<template>
	<v-card max-width="500px">
		<v-card-title>
			<h2 class="heading-bold primary--text my-2 mx-auto">{{ $t('actions.change_avatar') }}</h2>
		</v-card-title>
		<v-divider />

		<v-container class="px-6">
			<v-row
				no-gutters
				class="my-2"
			>
				<v-col
					cols="12"
					class="px-6"
				>
					<!-- show current IMG here -->
					<div
						class="text-center"
						v-if="showAvatar"
					>
						<v-img
							:src="currentImg"
							height="120px"
							alt="avatar-uploaded"
							contain
						/>
					</div>

					<avatar-cropper
						v-model="cropper"
						:upload-handler="uploadAvatar"
						:labels="{
							submit: $t('actions.ok'),
							cancel: $t('actions.cancel')
						}"
						inline
					/>
				</v-col>
			</v-row>
			<v-card-actions class="px-5">
				<v-row no-gutters>
					<v-col cols="12">
						<v-btn
							block
							text
							color="primary"
							@click="openCropper"
						>
							{{ $t('actions.upload') }}
						</v-btn>
					</v-col>
					<v-col cols="12">
						<v-btn
							block
							color="primary"
							elevation="0"
							class="white--text mt-2"
							:loading="loading"
							@click="close"
						>
							{{ $t('actions.cancel') }}
						</v-btn>
					</v-col>
				</v-row>
			</v-card-actions>
		</v-container>
	</v-card>
</template>

<script lang="ts">
import Vue from 'vue';
import AvatarCropper from 'vue-avatar-cropper';

import Api from '@/api';
import User from '@/models/user';

export default Vue.extend({
	name: 'AvatarUploader',

	components: { AvatarCropper },

	props: {
		user: {
			type: String,
			required: true
		},
		currentAvatar: String
	},

	data() {
		return {
			cropper: false,
			currentImg: '',
			showAvatar: true,
			imgURL: '',

			loading: false
		};
	},

	mounted() {
		if (this.currentAvatar.length > 0) {
			this.currentImg = this.currentAvatar;
		}
	},

	methods: {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		async uploadAvatar(cropper: any) {
			this.loading = true;

			cropper.getCroppedCanvas().toBlob(async (blob: Blob) => {
				const file = new File([blob], 'fileName.jpg', { type: blob.type });

				const res = await Api.user.uploadAvatar(this.user, file);
				if (res instanceof User) {
					this.currentImg = res.avatar;
					this.$emit('img', res.avatar);
				}

				this.showAvatar = true;
				this.loading = false;
			});
		},

		openCropper() {
			this.cropper = !this.cropper;
			this.showAvatar = false;
		},

		close() {
			this.currentImg = this.currentAvatar && this.currentAvatar.length > 0 ? this.currentAvatar : '';
			this.$emit('close');
		}
	}
});
</script>

<style lang="scss">
.avatar-cropper-btn {
	font-weight: 600 !important;
	border-radius: 4px;

	&:hover {
		background-color: #3f51b5 !important;
		color: #fff;
	}
}
</style>
