import Batch from './batch';
import AModel from './AModel';

class Partner extends AModel {
	id: string;
	bu: string;
	name: string;
	company: string;
	logo: string | null;

	districts: {
		id: string;
		name: string;
		country: string;
	}[];
	batches: Batch[];

	constructor(opt?: Partial<Partner>) {
		super();

		//default values (all props)
		this.id = '';
		this.bu = '';
		this.name = '';
		this.company = '';
		this.logo = null;

		this.districts = [];
		this.batches = [];

		if (opt) {
			//sanitize the input to keep only the fields that are needed for this model
			const sanitized = Object.assign({}, ...Object.keys(this).map((key) => ({ [key]: (key in opt ? opt : this)[key] })));

			//assign values passed to the constructor
			Object.assign<Partner, Partial<Partner>>(this, sanitized);

			//convert objects to classes
			if (this.batches.length > 0) {
				this.batches = this.batches.map((b) => {
					if (typeof b == 'string') {
						return b;
					}
					return new Batch(b);
				});
			}
		}
	}

	copy(): Partner {
		const copy = new Partner(this.toObject());
		return copy;
	}

	usesTours(): boolean {
		// only active for WEBA
		return this.id == '3815fb3d-1fd9-4ce8-901c-acb7526bd9cd';
	}

	forceGpsTracker(): boolean {
		// not for CZ
		return ['AT', 'DE'].includes(this.bu);
	}

	requireExpertReport(): boolean {
		// not for CZ
		return this.bu != 'CZ';
	}
}

export default Partner;
