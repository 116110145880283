import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"persistent":"","max-width":"450px"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,{staticClass:"pa-1"},[_c(VCardTitle,{class:`${_vm.color}--text`},[_c('h3',{staticClass:"heading-bold mt-1",domProps:{"innerHTML":_vm._s(_vm.titleDisplay)}})]),_c(VCardText,{staticClass:"mt-3 main-text",domProps:{"innerHTML":_vm._s(_vm.message)}}),_c(VCardActions,[_c(VRow,[_c(VCol,{attrs:{"cols":"6"}},[_c(VBtn,{attrs:{"elevation":"0","block":"","color":_vm.emphasizeCancel ? 'primary' : ''},on:{"click":_vm.clickNo}},[_vm._v(" "+_vm._s(_vm.$t('actions.no'))+" ")])],1),_c(VCol,{attrs:{"cols":"6"}},[_c(VBtn,{attrs:{"elevation":"0","block":"","color":_vm.emphasizeCancel ? '' : 'primary'},on:{"click":_vm.clickYes}},[_vm._v(" "+_vm._s(_vm.$t('actions.yes'))+" ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }