import { AxiosRequestHeaders, ResponseType } from 'axios';

export const getAuthHeader = (): { headers: AxiosRequestHeaders; responseType?: ResponseType } => {
	const auth = {
		headers: { Authorization: '' } as AxiosRequestHeaders
	};
	const token = localStorage.getItem('USER_TOKEN');
	if (token) {
		auth.headers.Authorization = `Bearer ${token}`;
	}
	return auth;
};

export class ErrorResponse {
	code: number;
	type: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data: any;
	message: string;
	timestamp: string;
	url: string;

	constructor(opt: ErrorResponse) {
		this.code = opt.code;
		this.type = opt.type;
		this.data = opt.data;
		this.message = opt.message;
		this.timestamp = opt.timestamp;
		this.url = opt.url;

		// eslint-disable-next-line no-console
		console.error(this);
	}
}

export class ListResponse<T> {
	total_entries: number;
	total_pages: number;
	page_size: number;
	page: number;
	entries: T[];

	constructor(typeT: new (opt?: Partial<T>) => T, response: ListResponse<Partial<T>>) {
		this.total_entries = response.total_entries;
		this.total_pages = response.total_pages;
		this.page_size = response.page_size;
		this.page = response.page;

		this.entries = [];
		for (const i in response.entries) {
			this.entries.push(new typeT(response.entries[i]));
		}
	}
}

export class ListRequestOrder {
	orderings: {
		field: string;
		direction: string;
	}[];

	constructor() {
		this.orderings = [];
	}

	addSorting(field: string, direction: string): ListRequestOrder {
		this.orderings.push({ field, direction });
		return this;
	}
}

export class ListRequestFilter {
	filters: ({ field: string; op: string; value: string | string[] | null } | ListRequestFilter)[];
	combiner: string;

	constructor() {
		this.combiner = 'AND';
		this.filters = [];
	}

	addFilter(field: string, op: string, value?: string | string[] | null): ListRequestFilter {
		value = value === undefined ? null : value;
		const filter = { field, op, value };
		this.filters.push(filter);
		return this;
	}

	addFilters(filters: ListRequestFilter): ListRequestFilter {
		this.filters.push(filters);
		return this;
	}

	toList(): Record<string, string> {
		const flat: { [k: string]: string } = {};
		let idx = 0;

		for (let i = 0; i < this.filters.length; i++) {
			const f = this.filters[i];

			if (f instanceof ListRequestFilter) {
				flat[`[${idx}][combiner]`] = f.combiner;
				const prefix = `[${idx}][filters]`;
				const tmpFlat = f.toList();
				for (const k in tmpFlat) {
					flat[prefix + k] = tmpFlat[k];
				}
			} else {
				flat[`[${idx}][field]`] = f.field;
				flat[`[${idx}][op]`] = f.op;
				if (Array.isArray(f.value)) {
					for (let j = 0; j < f.value.length; j++) {
						flat[`[${idx}][value][${j}]`] = f.value[j];
					}
				} else if (f.value !== null) {
					flat[`[${idx}][value]`] = f.value.toString();
				}
			}
			idx++;
		}
		return flat;
	}
}

export const buildListQuery = (page: number, page_size: number, filter?: ListRequestFilter, order?: ListRequestOrder, expands?: string[]): string => {
	const p = new URLSearchParams({
		page_size: page_size.toString(),
		page: page.toString()
	});

	if (filter) {
		const filterList = filter.toList();
		for (const k in filterList) {
			p.append('filter' + k, filterList[k]);
		}
	}

	if (order) {
		for (let i = 0; i < order.orderings.length; i++) {
			p.append(`order[${i}][0]`, order.orderings[i].field);
			p.append(`order[${i}][1]`, order.orderings[i].direction);
		}
	}

	if (expands) {
		for (let i = 0; i < expands.length; i++) {
			p.append(`expand[${i}]`, expands[i]);
		}
	}

	return `?${p}`;
};

export const stringifyExpands = (expands?: string[], only_attributes?: boolean): string => {
	if (!expands) {
		return '';
	}

	only_attributes = only_attributes === undefined ? true : only_attributes;

	const p = new URLSearchParams();
	for (let i = 0; i < expands.length; i++) {
		p.append(`expand[${i}]`, expands[i]);
	}

	const start_char = only_attributes ? '?' : '&';

	return `${start_char}${p}`;
};
