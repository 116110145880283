import axios, { AxiosError, AxiosResponse } from 'axios';
import Model from '@/models/model';

import { ListResponse, ListRequestOrder, ListRequestFilter, buildListQuery, stringifyExpands, ErrorResponse, getAuthHeader } from '../helper';

export const FetchModelList = async (
	page: number,
	page_size: number,
	filter?: ListRequestFilter,
	order?: ListRequestOrder,
	expands?: string[]
): Promise<ListResponse<Model> | ErrorResponse> => {
	const auth = getAuthHeader();
	const url = `/truck/model` + buildListQuery(page, page_size, filter, order, expands);

	return await axios
		.get(url, auth)
		.then((res: AxiosResponse) => {
			return new ListResponse<Model>(Model, res.data);
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};

export const GetOneModel = async (id: string, expands?: string[]): Promise<Model | ErrorResponse> => {
	const auth = getAuthHeader();
	const url = `/truck/model/${id}` + stringifyExpands(expands);

	return await axios
		.get(url, auth)
		.then((res: AxiosResponse) => {
			return new Model(res.data);
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};

export const CreateOneModel = async (model: Model, expands?: string[]): Promise<Model | ErrorResponse> => {
	const auth = getAuthHeader();
	const url = `/truck/model` + stringifyExpands(expands);

	return await axios
		.post(url, model.toDTO(), auth)
		.then((res: AxiosResponse) => {
			return new Model(res.data);
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};
export const UpdateOneModel = async (model: Model, expands?: string[]): Promise<Model | ErrorResponse> => {
	const auth = getAuthHeader();
	const url = `/truck/model/${model.id}` + stringifyExpands(expands);

	return await axios
		.put(url, model.toDTO(), auth)
		.then((res: AxiosResponse) => {
			return new Model(res.data);
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};

export const UploadModelFile = async (id: string, type: string, file: File, expands?: string[]): Promise<Model | ErrorResponse> => {
	const config = getAuthHeader();
	const url = `/truck/model/${id}/file?type=${type}` + stringifyExpands(expands, false);

	const formData = new FormData();
	formData.append('file', file);
	config.headers['Content-Type'] = 'multipart/form-data';

	return await axios
		.post(url, formData, config)
		.then((res: AxiosResponse) => {
			return new Model(res.data);
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};
