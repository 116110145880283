import AModel from './AModel';

class UserGroup extends AModel {
	id: string;
	name: string;
	freetext: string | null;
	SIDs: string[];

	constructor(opt?: Partial<UserGroup>) {
		super();

		//default values (all props)
		this.id = '';
		this.name = '';
		this.freetext = null;
		this.SIDs = [];

		if (opt) {
			//sanitize the input to keep only the fields that are needed for this model
			const sanitized = Object.assign({}, ...Object.keys(this).map((key) => ({ [key]: (key in opt ? opt : this)[key] })));

			//assign values passed to the constructor
			Object.assign<UserGroup, Partial<UserGroup>>(this, sanitized);
		}
	}

	copy(): UserGroup {
		const copy = new UserGroup(this.toObject());
		return copy;
	}
}

export default UserGroup;
