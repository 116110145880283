import AModel from './AModel';

class Stats extends AModel {
	bookings: {
		count: number;
		days: number;
		truck: number;
		protection: number;
		upsells: number;
		waiver: number;
		voucher: number;
		total: number;
	};
	special: {
		various: number;
		layout: number;
		penalty: number;
		fuel: number;
		damage: number;
		dunning: number;
		total: number;
	};
	expense: number;
	layout: number;
	total: number;

	constructor(opt?: Partial<Stats>) {
		super();

		//default values (all props)
		this.bookings = {
			count: 0,
			days: 0,
			truck: 0,
			protection: 0,
			upsells: 0,
			waiver: 0,
			voucher: 0,
			total: 0
		};
		this.special = {
			various: 0,
			layout: 0,
			penalty: 0,
			fuel: 0,
			damage: 0,
			dunning: 0,
			total: 0
		};
		this.expense = 0;
		this.layout = 0;
		this.total = 0;

		if (opt) {
			//sanitize the input to keep only the fields that are needed for this model
			const sanitized = Object.assign({}, ...Object.keys(this).map((key) => ({ [key]: (key in opt ? opt : this)[key] })));

			//assign values passed to the constructor
			Object.assign<Stats, Partial<Stats>>(this, sanitized);
		}
	}

	copy(): Stats {
		const copy = new Stats(this.toObject());
		return copy;
	}
}

export default Stats;
