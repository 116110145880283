import AModel from './AModel';
import Site from './site';
import Model from './model';
import Batch from './batch';
import Partner from './partner';
import Downtime from './downtime';

class Truck extends AModel {
	id: string;
	bu: string;
	state: TruckState;
	name: string;
	number: string;
	vin: string;

	model: string | Model | null;
	partner: string | Partner | null;
	batch: string | Batch | null;
	site: string | Site | null;
	location: string | Site | null;

	color: string;
	has_stick_on: boolean;
	mileage: number;
	start_mileage: number;
	plate: string;
	safety_valid_to: string;
	initial_register: string;
	last_cleaning: string;
	date_insurance_end: string;
	last_service: {
		date: string;
		mileage: number;
	};

	invers: {
		qnr: string;
		serial_number: string;
	};
	tablet: {
		id: string;
		state: TabletState;
	};
	gps: {
		id: string;
		sim: string;
	};

	short_link: string | null;
	tour_name: string | null;

	upcoming_deadlines: {
		[index: string]: string | null;

		cleaning: string | null;
		safety: string | null;
		service: string | null;
	};

	pictures: {
		[index: string]: string | null;

		front: string | null;
		front_driver: string | null;
		driver: string | null;
		co_driver: string | null;
		back: string | null;
		drivers_cab: string | null;
		driver_open: string | null;
		back_open: string | null;
		co_driver_open: string | null;
	};
	documents: {
		[index: string]: string | null;

		insurance: string | null;
		manual: string | null;
		registration: string | null;
	};

	constructor(opt?: Partial<Truck>) {
		super();

		//default values (all props)
		this.id = '';
		this.bu = 'AT';
		this.state = TruckState.OnTheWay;
		this.name = '';
		this.number = '';
		this.vin = '';
		this.color = '';
		this.has_stick_on = false;
		this.mileage = 0;
		this.start_mileage = 0;
		this.plate = '';
		this.safety_valid_to = '';
		this.initial_register = '';
		this.last_cleaning = '';
		this.date_insurance_end = '';
		this.last_service = {
			date: '',
			mileage: 0
		};

		this.model = null;
		this.partner = null;
		this.batch = null;
		this.site = null;
		this.location = null;

		this.invers = {
			qnr: '',
			serial_number: ''
		};

		this.tablet = {
			id: '',
			state: TabletState.NotInstalled
		};
		this.gps = {
			id: '',
			sim: ''
		};
		this.short_link = null;
		this.tour_name = null;

		this.upcoming_deadlines = {
			cleaning: null,
			safety: null,
			service: null
		};

		this.pictures = {
			front: null,
			front_driver: null,
			driver: null,
			co_driver: null,
			back: null,
			drivers_cab: null,
			driver_open: null,
			co_driver_open: null,
			back_open: null
		};
		this.documents = {
			insurance: null,
			manual: null,
			registration: null
		};

		if (opt) {
			//sanitize the input to keep only the fields that are needed for this model
			const sanitized = Object.assign({}, ...Object.keys(this).map((key) => ({ [key]: (key in opt ? opt : this)[key] })));

			//assign values passed to the constructor
			Object.assign<Truck, Partial<Truck>>(this, sanitized);
		}

		//convert objects to classes
		if (this.site && typeof this.site != 'string') {
			this.site = new Site(this.site);
		}
		if (this.location && typeof this.location != 'string') {
			this.location = new Site(this.location);
		}
		if (this.model && typeof this.model != 'string') {
			this.model = new Model(this.model);
		}
		if (this.partner && typeof this.partner != 'string') {
			this.partner = new Partner(this.partner);
		}
		if (this.batch && typeof this.batch != 'string') {
			this.batch = new Batch(this.batch);
		}
	}

	copy(): Truck {
		const copy = new Truck(this.toObject());
		return copy;
	}

	toDTO(): Record<string, unknown> {
		const dto = this.toObject();
		delete dto.documents;
		delete dto.pictures;
		delete dto.site;
		delete dto.location;
		delete dto.partner;
		delete dto.last_cleaning;
		// delete dto.last_service;

		if (this.model instanceof Model) {
			dto.model = this.model.id;
		}

		if (this.batch instanceof Batch) {
			dto.batch = this.batch.id;
		}

		return dto;
	}
}

export enum TruckState {
	Retired = 'RETIRED',
	Active = 'ACTIVE',
	UnplannedDowntime = 'DOWNTIME_UNPLANNED',
	PlannedDowntime = 'DOWNTIME_PLANNED',
	OnTheWay = 'ON_THE_WAY',
	Check = 'CHECKING'
}

export enum TabletState {
	Active = 'A',
	NotInstalled = 'I',
	HardwareFault = 'H',
	SoftwareFault = 'S',
	UnknownError = 'U'
}

export type TruckSlotInfo = {
	downtime?: Downtime;
	time_start: Date;
	time_end: Date;
	type: string;
};

export type TruckTelemetryStatus = {
	gps: {
		timestamp: string;
		lat: number;
		lng: number;
		dist_home: number;
	};
	tablet: {
		timestamp: string;
		network: string;
		version: string;
	};
	telemetry: {
		board_voltage: number;
		central_lock: boolean;
		fuel_level: number;
		ignition: boolean;
		immobilizer: boolean;
		keyfob: boolean;
		mileage: number;
		timestamp: string;
	};
};
export default Truck;
