import axios, { AxiosError, AxiosResponse } from 'axios';

import { ListResponse, ListRequestOrder, ListRequestFilter, buildListQuery, ErrorResponse, getAuthHeader } from '../helper';
import Todo from '@/models/todo';

export const FetchTodoList = async (
	page: number,
	page_size: number,
	filter?: ListRequestFilter,
	order?: ListRequestOrder,
	expands?: string[]
): Promise<ListResponse<Todo> | ErrorResponse> => {
	const auth = getAuthHeader();
	const url = `/partner/todo` + buildListQuery(page, page_size, filter, order, expands);

	return await axios
		.get(url, auth)
		.then((res: AxiosResponse) => {
			return new ListResponse<Todo>(Todo, res.data);
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const SetTodoDone = async (id: number, data?: any): Promise<boolean | ErrorResponse> => {
	const config = getAuthHeader();
	const url = `/partner/todo/${id}/done`;

	const formData = new FormData();
	if (data && data.files) {
		for (const i in data.files) {
			formData.append(`file[${i}]`, data.files[i]);
		}
		delete data.files;
	}

	if (data && data.expert_report) {
		formData.append(`expert_report`, data.expert_report);
		delete data.expert_report;
	}
	if (data && data.expert_fee) {
		formData.append(`expert_fee`, data.expert_fee);
		delete data.expert_fee;
	}

	// add rest of data
	if (data && Object.keys(data).length > 0) {
		formData.append('data', JSON.stringify(data));
	}

	config.headers['Content-Type'] = 'multipart/form-data';

	return await axios
		.post(url, formData, config)
		.then(() => {
			return true;
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};
export const ResetTodo = async (id: number): Promise<boolean | ErrorResponse> => {
	const config = getAuthHeader();
	const url = `/partner/todo/${id}/reset`;

	return await axios
		.delete(url, config)
		.then(() => {
			return true;
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};

export const FailTodo = async (id: number, description: string): Promise<boolean | ErrorResponse> => {
	const config = getAuthHeader();
	const url = `/partner/todo/${id}/fail`;

	return await axios
		.patch(url, { description }, config)
		.then(() => {
			return true;
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};

export const DownloadTodoList = async (): Promise<string | ErrorResponse> => {
	const auth = getAuthHeader();
	auth.responseType = 'blob';
	const url = `/partner/todo/export`;

	return await axios
		.get(url, auth)
		.then((res: AxiosResponse) => {
			return URL.createObjectURL(res.data);
		})
		.catch(async (err: AxiosError) => {
			const error = JSON.parse(await (err.response?.data as Blob).text()); // convert blob to text to json
			return new ErrorResponse(error);
		});
};
