import { RouteConfig } from 'vue-router';

import RoutesNames from './routesNames';

export const Routes: RouteConfig[] = [
	{
		path: '/',
		name: RoutesNames.home,
		component: () => import(/* webpackChunkName: "home" */ '@/views/general/Home.vue'),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/login',
		name: RoutesNames.authLogin,
		component: () => import(/* webpackChunkName: "authLogin" */ '@/views/user/AuthLogin.vue'),
		meta: {
			anonymousOnly: true
		}
	},
	{
		path: '/stats',
		name: RoutesNames.stats,
		component: () => import(/* webpackChunkName: "content" */ '@/views/main/StatsWrapper.vue'),
		meta: {
			requiresAuth: true,
			perm: 'partnerstats:list'
		}
	},
	{
		path: '/accounting',
		name: RoutesNames.accounting,
		component: () => import(/* webpackChunkName: "content" */ '@/views/main/AccountingWrapper.vue'),
		meta: {
			requiresAuth: true,
			perm: 'partneraccounting:list'
		}
	},
	{
		path: '/todo',
		name: RoutesNames.todo,
		component: () => import(/* webpackChunkName: "todo" */ '@/views/main/TodoWrapper.vue'),
		meta: {
			requiresAuth: true,
			perm: 'partnertodo:list'
		},
		children: [
			{
				path: 'archive',
				name: RoutesNames.todoArchive,
				meta: {
					requiresAuth: true
				},
				component: () => import(/* webpackChunkName: "todoArchiveList" */ '@/components/todo/TodoArchive.vue')
			},
			{
				path: ':id',
				name: RoutesNames.todoWorkorder,
				meta: {
					requiresAuth: true,
					perm: 'partnerworkorder:list'
				},
				component: () => import(/* webpackChunkName: "todoWorkorder" */ '@/components/todo/WorkorderWork.vue')
			}
		]
	},

	{
		path: '/user',
		name: RoutesNames.user,
		component: () => import(/* webpackChunkName: "Users" */ '@/views/main/UserWrapper.vue'),
		meta: {
			requiresAuth: true,
			perm: 'user:list'
		},
		children: [
			{
				path: 'create',
				name: RoutesNames.userCreate,
				meta: {
					requiresAuth: true,
					perm: 'user:create'
				},
				component: () => import(/* webpackChunkName: "UserCreate" */ '@/components/user/UserCreate.vue')
			},
			{
				path: ':id',
				name: RoutesNames.userEdit,
				meta: {
					requiresAuth: true,
					perm: 'user:get@*'
				},
				component: () => import(/* webpackChunkName: "UserEdit" */ '@/components/user/UserEdit.vue'),
				children: [
					{
						path: 'notifications',
						name: RoutesNames.userNotify,
						meta: {
							requiresAuth: true
						},
						component: () => import(/* webpackChunkName: "UserNotifications" */ '@/components/user/UserNotifications.vue')
					},
					{
						path: 'add_license',
						name: RoutesNames.userLicenseCreate,
						meta: {
							requiresAuth: true
						},
						component: () => import(/* webpackChunkNameL "UserLicenseCreate" */ '@/components/user/ui/LicenseForm.vue')
					}
				]
			}
		]
	},
	{
		path: '/model',
		name: RoutesNames.model,
		meta: {
			requiresAuth: true,
			perm: 'model:list'
		},
		component: () => import(/* webpackChunkName: "Model" */ '@/views/main/ModelWrapper.vue'),
		children: [
			{
				path: 'create',
				name: RoutesNames.modelCreate,
				meta: {
					requiresAuth: true,
					perm: 'model:create'
				},
				component: () => import(/* webpackChunkName: "Model" */ '@/components/model/ui/ModelForm.vue')
			},
			{
				path: ':id',
				name: RoutesNames.modelEdit,
				meta: {
					requiresAuth: true,
					perm: 'model:get@*'
				},
				component: () => import(/* webpackChunkName: "Model" */ '@/components/model/ui/ModelForm.vue')
			}
		]
	},
	{
		path: '/truck',
		name: RoutesNames.truck,
		component: () => import(/* webpackChunkName: "Truck" */ '@/views/main/TruckWrapper.vue'),
		meta: {
			requiresAuth: true,
			perm: 'truck:list'
		},
		children: [
			{
				path: 'create',
				name: RoutesNames.truckCreate,
				meta: {
					requiresAuth: true,
					perm: 'truck:create'
				},
				component: () => import(/* webpackChunkName: "TruckCreate" */ '@/components/truck/TruckCreate.vue')
			},
			{
				path: 'map',
				name: RoutesNames.truckMap,
				meta: {
					requiresAuth: true
				},
				component: () => import(/* webpackChunkName: "TruckMap" */ '@/components/truck/TruckMap.vue')
			},
			{
				path: ':id',
				name: RoutesNames.truckEdit,
				meta: {
					requiresAuth: true,
					perm: 'truck:get@*'
				},
				component: () => import(/* webpackChunkName: "TruckEdit" */ '@/components/truck/TruckEdit.vue')
			}
		]
	},
	{
		path: '/help-center',
		name: RoutesNames.helpCenter,
		component: () => import(/* webpackChunkName: "HelpCenter" */ '@/components/help/HelpCenter.vue'),
		children: [
			{
				path: 'thread/:id',
				name: RoutesNames.threadDetails,
				component: () => import(/* webpackChunkName: "ThreadDetails" */ '@/components/help/ThreadDetails.vue')
			}
		]
	},
	{
		path: '*',
		redirect: { name: RoutesNames.home }
	}
];
