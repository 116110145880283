<template>
	<v-app v-if="dataReady">
		<v-overlay
			opacity=".6"
			z-index="99"
			:value="langLoading"
			dark
		>
			<v-progress-circular
				indeterminate
				size="128"
			/>
		</v-overlay>

		<!-- Top bar -->
		<app-bar-main v-if="USER.is_logged_in" />

		<!-- Drawer menu -->
		<drawer-menu v-if="USER.is_logged_in" />

		<!-- Main body content -->
		<v-main class="bg-color">
			<router-view />
			<snack-bar />
		</v-main>

		<!-- Footer -->
		<v-footer
			fixed
			elevation="6"
			app
		>
			<!-- Footer grid -->
			<v-row width="100vw">
				<v-col
					cols="12"
					offset-sm="3"
					sm="6"
					class="text-center pb-0 pb-sm-3"
				>
					<!-- Copyright -->
					<span class="heading-bold mx-auto">&copy; {{ new Date().getFullYear() }} - 123-Transporter</span>
				</v-col>
				<v-col
					cols="12"
					sm="3"
					class="text-center text-sm-end pt-1 pt-sm-3"
				>
					<!-- Date and Time -->
					<small class="grey--text text--lighten-1">{{ today_date }} {{ today_time }}</small>
				</v-col>
			</v-row>
		</v-footer>
	</v-app>
</template>

<script lang="ts">
import mixins from 'vue-typed-mixins';
import { mapState } from 'vuex';

import LocaleHandler from '@/mixins/LocaleHandler';

import AppBarMain from '@/components/general/AppBarMain.vue';
import DrawerMenu from '@/components/general/ui/DrawerMenu.vue';
import SnackBar from '@/components/general/ui/SnackBar.vue';

export default mixins(LocaleHandler).extend({
	name: 'App',

	mixins: [LocaleHandler],

	components: { AppBarMain, SnackBar, DrawerMenu },

	created() {
		this.dataReady = true; // maybe wait for an event from the Locale switcher here.
	},

	data() {
		return {
			dataReady: false,
			langLoading: false,
			fixed: false,

			// variables for the date/clock in footer
			today_date: '',
			today_time: ''
		};
	},

	computed: {
		...mapState({
			USER: 'user'
		})
	},

	methods: {
		setDateTime() {
			// method for the date and clock in the footer
			setInterval(() => {
				const date = new Date();
				this.today_time = date.toLocaleTimeString();
				this.today_date = date.toLocaleDateString();
			}, 1000);
		}
	},

	mounted() {
		this.setDateTime();
	}
});
</script>

<style scoped>
.bg-color {
	background-color: #f6f6ff;
}
</style>
