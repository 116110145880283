import axios, { AxiosError, AxiosResponse } from 'axios';

import { ListResponse, ListRequestOrder, ListRequestFilter, buildListQuery, ErrorResponse, getAuthHeader } from '../helper';
import Partner from '@/models/partner';

export const FetchPartnerList = async (
	page: number,
	page_size: number,
	filter?: ListRequestFilter,
	order?: ListRequestOrder,
	expands?: string[]
): Promise<ListResponse<Partner> | ErrorResponse> => {
	const auth = getAuthHeader();
	const url = `/partner` + buildListQuery(page, page_size, filter, order, expands);
	return await axios
		.get(url, auth)
		.then((res: AxiosResponse) => {
			return new ListResponse<Partner>(Partner, res.data);
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};
