export const tokenValidityCheck = (): boolean => {
	const token = localStorage.getItem('USER_TOKEN');
	const currentTimeStamp = new Date().getTime();
	if (token) {
		const payload = token.split('.')[1]; // JStoken payload string
		const decodedPayload = JSON.parse(atob(payload));
		const tokenExpiration = decodedPayload.exp * 1000; // converts expiration value to actual time
		if (currentTimeStamp <= tokenExpiration + 60) {
			return true;
		}
	}
	localStorage.removeItem('USER_TOKEN');
	return false;
};

/** @deprecated */
export const toBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});

export const actionAllowed = (grant: string): boolean => {
	const jwt = localStorage.getItem('USER_TOKEN');
	if (jwt === null) {
		return false;
	}

	const statements = JSON.parse(atob(jwt.split('.')[1])).statements;

	let allowed = false;
	for (const stmt of statements) {
		if (stmt.Effect === 'Allow' && compareAction(stmt.Action, grant)) {
			allowed = true;
		}
	}
	if (allowed) {
		for (const stmt of statements) {
			if (stmt.Effect === 'Deny' && compareAction(stmt.Action, grant)) {
				allowed = false;
			}
		}
	}

	return allowed;
};

const compareAction = (statement_actions: string[], req_action: string): boolean => {
	for (const action of statement_actions) {
		if (action === req_action) {
			// exact match
			return true;
		} else if (action === '*') {
			// wildcard
			return true;
		} else if (action.includes('*')) {
			// partial wildcard
			const split = action.split('*');

			if (req_action.startsWith(split[0])) {
				return true;
			}
		}
	}

	return false;
};
