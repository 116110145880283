import i18n from '@/i18n';
import Vue from 'vue';
import { VForm } from '@/types';
import { ctyList, ICountry } from './CountryList';
import { InputValidationRule } from 'vuetify';

const emailRegex = RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
const phoneRegex = RegExp(/^\+?[0-9 ]*$/);

function checkImageMaxSize(file: File, max?: number): undefined | boolean {
	if (!file) {
		return;
	}
	const maxSize = 1024 * 1024 * (max ?? 5); // max or 5mb
	return file.size <= maxSize;
}

function checkImageFormat(file: File, add_type?: string[]): undefined | boolean {
	if (!file) {
		return;
	}
	const typesAccepted = ['image'];
	const formatAccepted = ['heic', 'heif', 'jpeg', 'png'];

	if (add_type) {
		add_type.forEach((el: string) => {
			const [t, f] = el.split('/');
			typesAccepted.push(t);
			formatAccepted.push(f);
		});
	}
	const [type, format] = file.type.split('/');
	if (type && format) {
		return typesAccepted.includes(type) && formatAccepted.includes(format);
	}
}

interface IRules {
	required: InputValidationRule;
	email: InputValidationRule;
	phone: InputValidationRule;
	is_integer: InputValidationRule;
	file: {
		max_size: InputValidationRule;
		format: InputValidationRule;
	};
}

const rules = Vue.extend({
	computed: {
		rules(): IRules {
			return {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				required: (v: any) => !!v || (i18n.t('alert.validation.required') as string),
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				email: (v: any) => emailRegex.test(v) || (i18n.t('alert.validation.invalid_email') as string),
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				phone: (v: any) => phoneRegex.test(v) || (i18n.t('alert.validation.invalid_phone') as string),
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				is_integer: (v: any) => Number.isInteger(parseFloat(v)) || (i18n.t('alert.validation.no_integer') as string),
				file: {
					max_size: (v: File) => checkImageMaxSize(v) || (i18n.t('alert.validation.file_too_large', { max: 5 }) as string),
					format: (v: File) => checkImageFormat(v) || (i18n.t('alert.validation.file_wrong_format') as string)
				}
			};
		}
	},

	methods: {
		createPostcodeRule(country: ICountry) {
			const countries = ctyList;
			let countryExp = null;
			for (const c in countries) {
				if (countries[c].code == country.code) {
					countryExp = countries[c].pcp;
				}
			}
			const rule = '^(' + countryExp + ')$';
			const reg = new RegExp(rule);
			return (v: string) => reg.test(v) || (i18n.tc('alert.validation.invalid_postcode') as string);
		},

		// Set a custom file max size in Mb
		setMaxFileSize(max_size: number) {
			return (v: File) => {
				if (!v) {
					return true;
				}
				return checkImageMaxSize(v, max_size) || (i18n.t('alert.validation.file_too_large', { max: max_size }) as string);
			};
		},

		// Add a list of file types to be accepted by the rule eg ['application/pdf']
		addFileTypeAccepted(list: string[]) {
			return (v: File) => {
				if (!v) {
					return true;
				}
				return checkImageFormat(v, list) || (i18n.t('alert.validation.file_wrong_format') as string);
			};
		},

		clearErrors(_name: string) {
			// const fieldErrors = this.errorList.errors?.field ?? [];
			// for (
			// 	let i = 0;
			// 	i < fieldErrors.length;
			// 	i++ // for(const i in fieldErrors)
			// ) {
			// 	if (fieldErrors[i].field == name) {
			// 		this.errorList.errors?.field?.splice(i, 1);
			// 	}
			// }
		},

		fieldRule(_name: string) {
			return true;
			// return (): boolean | string => {
			// 	const fieldErrors = this.errorList.errors?.field ?? [];
			// 	for (const i in fieldErrors) {
			// 		if (fieldErrors[i].field == name) {
			// 			return i18n.t('alert.validation.' + fieldErrors[i].msg) as string;
			// 		}
			// 	}
			// 	return true;
			// };
		},

		scrollToError(form: VForm, dialog?: boolean): void {
			for (const i in form.errorBag) {
				if (form.errorBag[i] == true) {
					const el = form.$children.find((c) => {
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						return (c as any)._uid == i;
					});
					if (el !== undefined) {
						if (dialog) {
							el.$el.scrollIntoView({ behavior: 'smooth' });
						}
						this.$vuetify.goTo(el.$el as HTMLElement, { offset: 10 }); //64px height of navBar
						break;
					}
				}
			}
		}
	}
});

export type rulesRef = InstanceType<typeof rules>;
export default rules;
