import AModel from './AModel';
import Truck from './truck';

class Downtime extends AModel {
	id: string;
	time_end: string;
	time_start: string;
	truck: Truck | string | null;

	constructor(opt?: Partial<Downtime>) {
		super();

		//default values (all props)
		this.id = '';
		this.time_end = '';
		this.time_start = '';

		this.truck = null;

		if (opt) {
			//sanitize the input to keep only the fields that are needed for this model
			const sanitized = Object.assign({}, ...Object.keys(this).map((key) => ({ [key]: (key in opt ? opt : this)[key] })));

			//assign values passed to the constructor
			Object.assign<Downtime, Partial<Downtime>>(this, sanitized);

			if (this.truck && typeof this.truck != 'string') {
				this.truck = new Truck(this.truck);
			}
		}
	}

	copy(): Downtime {
		const copy = new Downtime(this.toObject());
		return copy;
	}
}

export default Downtime;
