/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { VueConstructor } from 'vue';

declare module 'vue/types/vue' {
	interface Vue {
		$snackbar: any;
	}
}

const snackbarPlugin = {
	install: (Vue: VueConstructor, { store }: any): void => {
		if (!store) {
			throw new Error('Please provide vuex store. (snackbar plugin)');
		}

		Vue.prototype.$snackbar = {
			showMessage: function ({ content = '', type = '', color = '', time = 0, position = '' }): void {
				store.commit('showSnackbar', { content, type, color, time, position }, { root: true });
			}
		};
	}
};

export default snackbarPlugin;
