import AModel from './AModel';
import Thread from './thread';
import User from './user';

export default class ThreadEvent extends AModel {
	id: string;
	thread: Thread | string | null;
	type: EnumEventType;
	user_create: User | string | null;
	date_create: string;
	text: string | null;
	attachment: string | null;

	constructor(opt?: Partial<ThreadEvent>) {
		super();

		this.id = '';
		this.thread = null;
		this.type = EnumEventType.Text;
		this.user_create = null;
		this.date_create = '';
		this.text = '';
		this.attachment = null;

		if (opt) {
			//sanitize the input to keep only the fields that are needed for this model
			const sanitized = Object.assign({}, ...Object.keys(this).map((key) => ({ [key]: (key in opt ? opt : this)[key] })));

			//assign values passed to the constructor
			Object.assign<ThreadEvent, Partial<ThreadEvent>>(this, sanitized);

			if (this.thread && typeof this.thread != 'string') {
				this.thread = new Thread(this.thread);
			}
			if (this.user_create && typeof this.user_create != 'string') {
				this.user_create = new User(this.user_create);
			}
		}
	}
}

export enum EnumEventType {
	Create = 'CREATE',
	Text = 'TEXT',
	Attachment = 'ATTACHMENT',
	Close = 'CLOSE',
	Reopen = 'REOPEN'
}
