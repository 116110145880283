import axios, { AxiosError, AxiosResponse } from 'axios';
import User from '@/models/user';

import { ListResponse, ListRequestOrder, ListRequestFilter, buildListQuery, stringifyExpands, ErrorResponse, getAuthHeader } from '../helper';
import UserGroup from '@/models/usergroup';
import NotificationSetting from '@/models/notificationSetting';
import IdDoc from '@/models/iddoc';

export const FetchUserList = async (
	page: number,
	page_size: number,
	filter?: ListRequestFilter,
	order?: ListRequestOrder,
	expands?: string[]
): Promise<ListResponse<User> | ErrorResponse> => {
	const auth = getAuthHeader();
	const url = `/user` + buildListQuery(page, page_size, filter, order, expands);
	return await axios
		.get(url, auth)
		.then((res: AxiosResponse) => {
			return new ListResponse<User>(User, res.data);
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};

export const GetOneUser = async (id: string, expands?: string[]): Promise<User | ErrorResponse> => {
	const auth = getAuthHeader();
	const url = `/user/${id}` + stringifyExpands(expands);

	return await axios
		.get(url, auth)
		.then((res: AxiosResponse) => {
			return new User(res.data);
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};

export const UpdateOneUser = async (id: string, user: User, expands?: string[]): Promise<User | ErrorResponse> => {
	const auth = getAuthHeader();
	const url = `/user/${id}` + stringifyExpands(expands);

	return await axios
		.put(url, user.toDTO(), auth)
		.then((res: AxiosResponse) => {
			return new User(res.data);
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};

export const CreateOneUser = async (user: User, expands?: string[]): Promise<User | ErrorResponse> => {
	const auth = getAuthHeader();
	const url = `/user` + stringifyExpands(expands);

	return await axios
		.post(url, user.toDTO(), auth)
		.then((res: AxiosResponse) => {
			return new User(res.data);
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};

export const SetUserBatches = async (id: string, batches: string[]): Promise<boolean | ErrorResponse> => {
	const auth = getAuthHeader();
	const url = `/user/${id}/batch`;

	return await axios
		.patch(url, batches, auth)
		.then(() => {
			return true;
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};

export const AddGroup = async (user_id: string, group_id: string): Promise<boolean | ErrorResponse> => {
	const auth = getAuthHeader();
	const url = `/user/${user_id}/group?group=${group_id}`;

	return await axios
		.patch(url, undefined, auth)
		.then(() => {
			return true;
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};

export const RemoveGroup = async (user_id: string, group_id: string): Promise<boolean | ErrorResponse> => {
	const auth = getAuthHeader();
	const url = `/user/${user_id}/group?group=${group_id}`;

	return await axios
		.delete(url, auth)
		.then(() => {
			return true;
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};

export const ResetPassword = async (user_id: string): Promise<boolean | ErrorResponse> => {
	const auth = getAuthHeader();
	const url = `/user/${user_id}/password`;

	return await axios
		.patch(url, undefined, auth)
		.then(() => {
			return true;
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};

export const FetchUserGroupList = async (
	page: number,
	page_size: number,
	filter?: ListRequestFilter,
	order?: ListRequestOrder,
	expands?: string[]
): Promise<ListResponse<UserGroup> | ErrorResponse> => {
	const auth = getAuthHeader();
	const url = `/user/group` + buildListQuery(page, page_size, filter, order, expands);
	return await axios
		.get(url, auth)
		.then((res: AxiosResponse) => {
			return new ListResponse<UserGroup>(UserGroup, res.data);
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};

export const FetchNotificationSettings = async (user_id: string, expands?: string[]): Promise<ListResponse<NotificationSetting> | ErrorResponse> => {
	const auth = getAuthHeader();
	const url = `/user/${user_id}/notification/settings` + stringifyExpands(expands);
	return await axios
		.get(url, auth)
		.then((res: AxiosResponse) => {
			return new ListResponse<NotificationSetting>(NotificationSetting, res.data);
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};

export const UpdateNotificationSettings = async (user_id: string, setting: NotificationSetting): Promise<boolean | ErrorResponse> => {
	const auth = getAuthHeader();
	const url = `/user/${user_id}/notification/settings/${setting.type}`;

	return await axios
		.put(url, setting.toDTO(), auth)
		.then(() => {
			return true;
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};

export const MultiUpdateNotificationSettings = async (user_id: string, settings: NotificationSetting[]): Promise<boolean | ErrorResponse> => {
	const auth = getAuthHeader();
	const url = `/user/${user_id}/notification/settings`;

	const payload = settings.map((s) => s.toDTO());

	return await axios
		.put(url, payload, auth)
		.then(() => {
			return true;
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};

export const AddIdDoc = async (user_id: string, id_doc: IdDoc, expands?: string[]): Promise<User | ErrorResponse> => {
	const config = getAuthHeader();
	const url = `/user/${user_id}/id-doc` + stringifyExpands(expands);

	config.headers['Content-Type'] = 'multipart/form-data';

	const formData = new FormData();
	formData.append('picture_front', id_doc.picture_front as File);
	formData.append('picture_back', id_doc.picture_back as File);
	formData.append('data', JSON.stringify(id_doc.toDTO()));

	return await axios
		.post(url, formData, config)
		.then((res: AxiosResponse) => {
			return new User(res.data);
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};

export const UploadAvatar = async (id: string, file: File, expands?: string[]): Promise<User | ErrorResponse> => {
	const config = getAuthHeader();
	const url = `/user/${id}/avatar` + stringifyExpands(expands);

	const formData = new FormData();
	formData.append('file', file);
	config.headers['Content-Type'] = 'multipart/form-data';

	return await axios
		.post(url, formData, config)
		.then((res: AxiosResponse) => {
			return new User(res.data);
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};
