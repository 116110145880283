import Vue from 'vue';
import Vuex from 'vuex';
import router from '@/router';

import Api, { ErrorResponse, ListRequestOrder, ListResponse } from '@/api';

import User from '@/models/user';
// import { User } from '@/models';
import Model from '@/models/model';
import Partner from '@/models/partner';
import Truck from '@/models/truck';
import Batch from '@/models/batch';
import UserGroup from '@/models/usergroup';
import { IDictionary } from '@/models';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		//utlitities
		drawerMenu: false,

		// Data
		user: new User(),
		partner: new Partner(),

		snackbar: {
			content: '',
			type: '',
			color: '',
			time: 0,
			position: 'top'
		},

		truckCache: {} as IDictionary<Truck>,
		truckRequests: {} as IDictionary<Promise<Truck | ErrorResponse>>,

		userCache: {} as IDictionary<User>,
		userRequests: {} as IDictionary<Promise<User | ErrorResponse>>,

		modelList: [] as Model[],
		_modelsLoaded: false as boolean,

		userList: [] as User[],
		_usersLoaded: false as boolean,

		userGroupList: [] as UserGroup[],
		_userGroupsLoaded: false as boolean
	},

	mutations: {
		drawerMenuState(state, isOpen: boolean) {
			state.drawerMenu = isOpen;
		},

		storePartner(state, partner: Partner) {
			state.partner = partner;
		},

		storeUser(state, user: User) {
			state.user = user;
		},

		showSnackbar(state, payload) {
			state.snackbar.content = payload.content;
			state.snackbar.type = payload.type;
			state.snackbar.color = payload.color;
			if (payload.time > 0) {
				state.snackbar.time = payload.time;
			}
			if (payload.position) {
				state.snackbar.position = payload.position;
			}
		},

		cacheTruck(state, truck: Truck) {
			if (truck.id !== null) {
				state.truckCache[truck.id] = truck;
			}
		},

		cacheUser(state, user: User) {
			if (user.id !== null) {
				state.userCache[user.id] = user;
			}
		},

		storeModelList(state, list: Model[]) {
			state.modelList = list;
		},

		storeUserGroupList(state, list: UserGroup[]) {
			state.userGroupList = list;
		},

		storeUserList(state, list: User[]) {
			state.userList = list;
		}
	},

	actions: {
		GET_BATCH(context, id: string): Batch | undefined {
			for (let i = 0; i < context.state.partner.batches.length; i++) {
				if (context.state.partner.batches[i].id == id) {
					return context.state.partner.batches[i];
				}
			}
			return undefined;
		},

		STORE_USER(context, user: User) {
			context.commit('storeUser', user);
		},

		async USER_LOGIN(context, params: { user: string; password: string }): Promise<boolean> {
			const resv2 = await Api.auth.login(params.user, params.password);

			if (resv2 instanceof ErrorResponse) {
				return false;
			}

			const jwt = resv2.access_token;
			if (jwt === null) {
				return false;
			}

			localStorage.setItem('USER_TOKEN', resv2.access_token);

			const user_id = JSON.parse(atob(jwt.split('.')[1])).sub;

			const user = await Api.user.get(user_id, ['batches']);
			if (user instanceof ErrorResponse) {
				localStorage.removeItem('USER_TOKEN');
				return false;
			}

			const partners = await Api.partner.list(0, 1, undefined, undefined, ['batches']);
			if (partners instanceof ErrorResponse || partners.total_entries < 1) {
				localStorage.removeItem('USER_TOKEN');
				return false;
			}

			user.is_logged_in = true;
			context.commit('storeUser', user);

			const partner = partners.entries[0];
			context.commit('storePartner', partner);

			return true;
		},

		async USER_LOGOUT(context) {
			const res = await Api.auth.logout();
			if (res) {
				const user = new User();
				user.is_logged_in = false;

				context.commit('storeUser', user);

				localStorage.removeItem('USER_TOKEN');

				router.replace({ name: 'Login' });
				return true;
			}
			return false;
		},

		async GET_TRUCK(context, id: string): Promise<Truck | boolean> {
			//if in store directly return
			if (context.state.truckCache[id] !== undefined) {
				return context.state.truckCache[id] as Truck;
			}

			//if a request is currently running wait for completion
			if (context.state.truckRequests[id] !== undefined) {
				await context.state.truckRequests[id];
			} else {
				const req = Api.truck.get(id);

				//store request in reqCache
				context.state.truckRequests[id] = req;

				//wait for req to be finished
				const truck = await req;

				//store result
				if (truck !== undefined) {
					context.commit('cacheTruck', truck);
				}
			}

			//handle stored data & return
			if (context.state.truckCache[id] !== undefined) {
				return context.state.truckCache[id] as Truck;
			}

			return false;
		},

		async GET_USER(context, id: string): Promise<User | boolean> {
			//if in store directly return
			if (context.state.userCache[id] !== undefined) {
				return context.state.userCache[id] as User;
			}

			//if a request is currently running wait for completion
			if (context.state.userRequests[id] !== undefined) {
				await context.state.userRequests[id];
			} else {
				const req = Api.user.get(id, ['self:min']);

				//store request in reqCache
				context.state.userRequests[id] = req;

				//wait for req to be finished
				const user = await req;

				//store result
				if (user !== undefined) {
					context.commit('cacheUser', user);
				}
			}

			//handle stored data & return
			if (context.state.userCache[id] !== undefined) {
				return context.state.userCache[id] as User;
			}

			return false;
		},

		async GET_MODELS(context): Promise<Model[]> {
			if (!context.state._modelsLoaded) {
				const order = new ListRequestOrder();
				order.addSorting('name', 'asc');
				const resv2 = await Api.model.list(0, 999, undefined, order);

				if (resv2 instanceof ListResponse) {
					context.commit('storeModelList', resv2.entries);
				}

				context.state._modelsLoaded = true;
			}

			return context.state.modelList;
		},

		async GET_USER_LIST(context): Promise<User[] | undefined> {
			if (!context.state._usersLoaded) {
				const resv2 = await Api.user.list(0, 999);

				if (resv2 instanceof ListResponse) {
					context.commit('storeUserList', resv2.entries);
				}

				context.state._usersLoaded = true;
			}
			return context.state.userList;
		},

		async GET_USERGROUP_LIST(context): Promise<UserGroup[] | undefined> {
			if (!context.state._userGroupsLoaded) {
				const order = new ListRequestOrder();
				order.addSorting('name', 'asc');
				const resv2 = await Api.user.group.list(0, 999, undefined, order);

				if (resv2 instanceof ListResponse) {
					context.commit('storeUserGroupList', resv2.entries);
				}

				context.state._userGroupsLoaded = true;
			}

			return context.state.userGroupList;
		}
	},

	modules: {}
});
