import axios, { AxiosError, AxiosResponse } from 'axios';

import { ErrorResponse, getAuthHeader } from '../helper';

export type WidgetInfo = {
	todos: {
		open: number;
		overdue: number;
	};
};

export type WidgetLast30 = {
	mileage: number;
	revenue: number;
	share: number;
};
export type WidgetTruckActiveCount = {
	down: number;
	active: number;
};
export type WidgetTruckActive = {
	day: string;
	value: number;
}[];

export const getWidgetInfo = async (partner_id: string): Promise<WidgetInfo | ErrorResponse> => {
	const auth = getAuthHeader();
	const url = `/partner/${partner_id}/widget/info`;

	return await axios
		.get(url, auth)
		.then((res: AxiosResponse) => {
			return res.data as WidgetInfo;
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};
export const getWidgetLast30 = async (partner_id: string, batch: string): Promise<WidgetLast30 | ErrorResponse> => {
	const auth = getAuthHeader();
	let url = `/partner/${partner_id}/widget/last30`;
	if (batch) {
		url += `?batch=${batch}`;
	}

	return await axios
		.get(url, auth)
		.then((res: AxiosResponse) => {
			return res.data as WidgetLast30;
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};
export const getTruckActiveCount = async (partner_id: string, start: string, end: string, batch: string): Promise<WidgetTruckActiveCount | ErrorResponse> => {
	const auth = getAuthHeader();
	let url = `/partner/${partner_id}/widget/truck/active-count?start=${start}&end=${end}`;
	if (batch) {
		url += `&batch=${batch}`;
	}

	return await axios
		.get(url, auth)
		.then((res: AxiosResponse) => {
			return res.data as WidgetTruckActiveCount;
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};

export const getTruckActive = async (partner_id: string, start: string, end: string, batch: string): Promise<WidgetTruckActive | ErrorResponse> => {
	const auth = getAuthHeader();
	let url = `/partner/${partner_id}/widget/truck/active?start=${start}&end=${end}`;
	if (batch) {
		url += `&batch=${batch}`;
	}

	return await axios
		.get(url, auth)
		.then((res: AxiosResponse) => {
			return res.data as WidgetTruckActive;
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};

export const getTruckLoad = async (partner_id: string, start: string, end: string, batch: string): Promise<WidgetTruckActive | ErrorResponse> => {
	const auth = getAuthHeader();
	let url = `/partner/${partner_id}/widget/truck/load?start=${start}&end=${end}`;
	if (batch) {
		url += `&batch=${batch}`;
	}

	return await axios
		.get(url, auth)
		.then((res: AxiosResponse) => {
			return res.data as WidgetTruckActive;
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};
