import axios, { AxiosError, AxiosResponse } from 'axios';

import { ListResponse, ListRequestOrder, ListRequestFilter, buildListQuery, ErrorResponse, stringifyExpands, getAuthHeader } from '../helper';

import Thread, { IThread4Create } from '@/models/thread';
import ThreadEvent, { EnumEventType } from '@/models/threadEvent';

export const FetchThreadList = async (
	page: number,
	page_size: number,
	filter?: ListRequestFilter,
	order?: ListRequestOrder,
	expands?: string[]
): Promise<ListResponse<Thread> | ErrorResponse> => {
	const auth = getAuthHeader();
	const url = `/thread` + buildListQuery(page, page_size, filter, order, expands);

	return await axios
		.get(url, auth)
		.then((res: AxiosResponse) => {
			return new ListResponse<Thread>(Thread, res.data);
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};

export const CreateThread = async (payload: IThread4Create): Promise<Thread | ErrorResponse> => {
	const auth = getAuthHeader();
	const url = '/thread';

	return await axios
		.post(url, payload, auth)
		.then((res: AxiosResponse) => {
			return new Thread(res.data);
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};

export const FetchThread = async (id: string, expands?: string[]): Promise<Thread | ErrorResponse> => {
	const auth = getAuthHeader();
	const url = `/thread/${id}` + stringifyExpands(expands);

	return await axios
		.get(url, auth)
		.then((res: AxiosResponse) => {
			return new Thread(res.data);
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};

export const CreateEvent = async (id: string, type: EnumEventType, payload: { text: string } | { file: File }, expands?: string[]): Promise<ThreadEvent | ErrorResponse> => {
	const auth = getAuthHeader();
	const url = `/thread/${id}/event/${type.toLowerCase()}` + stringifyExpands(expands);
	let data;

	if (type === EnumEventType.Attachment) {
		data = new FormData();
		data.append('file', (payload as { file: File }).file);
		auth.headers['Content-Type'] = 'multipart/form-data';
	} else {
		data = payload;
	}

	return await axios
		.post(url, data, auth)
		.then((res: AxiosResponse) => {
			return new ThreadEvent(res.data);
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};

export const FetchEventList = async (
	id: string,
	page: number,
	page_size: number,
	order?: ListRequestOrder,
	expands?: string[]
): Promise<ListResponse<ThreadEvent> | ErrorResponse> => {
	const auth = getAuthHeader();
	const url = `/thread/${id}/event` + buildListQuery(page, page_size, undefined, order, expands);

	return await axios
		.get(url, auth)
		.then((res: AxiosResponse) => {
			return new ListResponse<ThreadEvent>(ThreadEvent, res.data);
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};

export const CloseThread = async (id: string): Promise<Thread | ErrorResponse> => {
	const auth = getAuthHeader();
	const url = `/thread/${id}/close`;

	return await axios
		.patch(url, undefined, auth)
		.then((res: AxiosResponse) => {
			return new Thread(res);
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};
