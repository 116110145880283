import AModel from './AModel';
import Partner from './partner';
import User from './user';
import Truck from './truck';
import Workorder from './workorder';

class Todo extends AModel {
	id: number;
	type: TodoType;
	state: TodoState;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data: any;
	problem_description: string | null;

	date_deadline: string;
	date_create: string;
	date_complete: string | null;

	workorder: Workorder | string | null;
	partner: Partner | string | null;
	truck: Truck | string | null;
	user_create: User | string | null;
	user_complete: User | string | null;

	constructor(opt?: Partial<Todo>) {
		super();

		//default values (all props)
		this.id = 0;
		this.state = TodoState.New;
		this.type = TodoType.Cleaning;
		this.problem_description = null;
		this.data = null;

		this.date_deadline = '';
		this.date_create = '';
		this.date_complete = null;

		this.workorder = null;
		this.partner = null;
		this.truck = null;
		this.user_create = null;
		this.user_complete = null;

		if (opt) {
			//sanitize the input to keep only the fields that are needed for this model
			const sanitized = Object.assign({}, ...Object.keys(this).map((key) => ({ [key]: (key in opt ? opt : this)[key] })));

			//assign values passed to the constructor
			Object.assign<Todo, Partial<Todo>>(this, sanitized);

			if (this.partner && typeof this.partner != 'string') {
				this.partner = new Partner(this.partner);
			}
			if (this.truck && typeof this.truck != 'string') {
				this.truck = new Truck(this.truck);
			}
			if (this.user_complete && typeof this.user_complete != 'string') {
				this.user_complete = new User(this.user_complete);
			}
			if (this.user_create && typeof this.user_create != 'string') {
				this.user_create = new User(this.user_create);
			}
			if (this.workorder && typeof this.workorder != 'string') {
				this.workorder = new Workorder(this.workorder);
			}
		}
	}

	copy(): Todo {
		const copy = new Todo(this.toObject());
		return copy;
	}

	toDTO(): Record<string, unknown> {
		const dto = this.toObject();
		delete dto.id;
		delete dto.date_deadline;
		delete dto.date_create;
		delete dto.date_complete;
		delete dto.user_create;
		delete dto.user_complete;

		delete dto.partner;
		delete dto.workorder;

		if (this.truck instanceof Truck) {
			dto.truck = this.truck.id;
		}

		return dto;
	}

	isSimple(): boolean {
		switch (this.type) {
			case TodoType.Fines:
			case TodoType.Costs:
			case TodoType.Insurance:
			case TodoType.SafetyReport:
				return true;
		}
		return false;
	}

	getIcon(): string {
		switch (this.type) {
			case TodoType.Cleaning:
				return 'mdi-spray-bottle';
			case TodoType.Safety:
				return 'mdi-seatbelt';
			case TodoType.Service:
				return 'mdi-hammer-wrench';
			case TodoType.Damage:
				return 'mdi-fire-alert';
			case TodoType.Location:
				return 'mdi-map-marker-radius';
			case TodoType.Fines:
			case TodoType.Insurance:
			case TodoType.SafetyReport:
				return 'mdi-upload';
			case TodoType.Costs:
				return 'mdi-wrench-clock';
			case TodoType.Recovery:
				return 'mdi-car-arrow-right';
		}
	}
}

export enum TodoType {
	Cleaning = 'cleaning',
	Costs = 'costs',
	Damage = 'damage',
	Fines = 'fines',
	Location = 'location',
	Service = 'service',
	Safety = 'safety',
	Insurance = 'insurance',
	Recovery = 'recovery',
	SafetyReport = 'safetyreport'
}
export enum TodoState {
	New = 'NEW',
	Delegated = 'DELEGATED',
	Done = 'DONE',
	Complete = 'COMPLETE',
	Failed = 'FAILED',
	Expired = 'EXPIRED'
}

export default Todo;
