import AModel from './AModel';
import ThreadEvent from './threadEvent';
import User from './user';

export default class Thread extends AModel {
	id: string;
	title: string | null;
	topic: EnumThreadTopic;
	state: EnumThreadState;
	user_assigned: User | string | null;
	user_create: User | string | null;
	date_create: string;
	date_update: string;
	events: ThreadEvent[];

	constructor(opt?: Partial<Thread>) {
		super();

		this.id = '';
		this.title = '';
		this.topic = EnumThreadTopic.Hardware;
		this.state = EnumThreadState.Waiting_123;
		this.user_assigned = null;
		this.user_create = null;
		this.date_create = '';
		this.date_update = '';
		this.events = [];

		if (opt) {
			//sanitize the input to keep only the fields that are needed for this model
			const sanitized = Object.assign({}, ...Object.keys(this).map((key) => ({ [key]: (key in opt ? opt : this)[key] })));

			//assign values passed to the constructor
			Object.assign<Thread, Partial<Thread>>(this, sanitized);

			if (this.events) {
				this.events = this.events.map((event) => {
					return new ThreadEvent(event);
				});
			}

			if (this.user_assigned && typeof this.user_assigned != 'string') {
				this.user_assigned = new User(this.user_assigned);
			}

			if (this.user_create && typeof this.user_create != 'string') {
				this.user_create = new User(this.user_create);
			}
		}
	}

	copy(): Thread {
		const copy = new Thread(this.toObject());
		return copy;
	}
}

export interface IThread4Create {
	title: string;
	topic: EnumThreadTopic | null;
	text: string;
	desired_answer: string;
}

export enum EnumThreadTopic {
	Hardware = 'HARDWARE',
	Billing = 'BILLING',
	Other = 'OTHER',
	Software = 'SOFTWARE'
}

export enum EnumThreadState {
	Waiting_123 = 'W_123',
	Waiting_partner = 'W_P',
	Closed_123 = 'C_123',
	Closed_partner = 'C_P'
}
