import { getStartingLocale } from '@/i18n';
import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import de from 'vuetify/src/locale/de';
import en from 'vuetify/src/locale/en';

Vue.use(Vuetify, {
	options: {
		customProperties: true
	}
});

export default new Vuetify({
	lang: {
		locales: { de, en },
		current: getStartingLocale()
	},
	theme: {
		themes: {
			light: {
				primary: '#3F51B5',
				secondary: '#FF8811',
				accent: '#392F5A'
			}
		},
		options: { customProperties: true }
	}
});
