import AModel from './AModel';
import User from './user';

class NotificationSetting extends AModel {
	id: string;
	type: NotificationType;
	group: NotificationGroup;
	send_email: boolean;
	send_sms: boolean;
	user: string | User | null;

	constructor(opt?: Partial<NotificationSetting>) {
		super();

		//default values (all props)
		this.id = '';
		this.type = NotificationType.AlertTruckBattery;
		this.group = NotificationGroup.Alert;
		this.send_email = false;
		this.send_sms = false;
		this.user = null;

		if (opt) {
			//sanitize the input to keep only the fields that are needed for this model
			const sanitized = Object.assign({}, ...Object.keys(this).map((key) => ({ [key]: (key in opt ? opt : this)[key] })));

			//assign values passed to the constructor
			Object.assign<NotificationSetting, Partial<NotificationSetting>>(this, sanitized);
		}
	}

	copy(): NotificationSetting {
		const copy = new NotificationSetting(this.toObject());
		return copy;
	}

	toDTO(): Record<string, unknown> {
		const dto = this.toObject();
		delete dto.id;
		delete dto.group;
		delete dto.user;

		return dto;
	}
}

export enum NotificationType {
	AlertTruckBattery = 'alert_truck_battery',
	TodoCleaning = 'todo_cleaning',
	TodoSafetyCheck = 'todo_safety',
	TodoService = 'todo_service',
	TodoTrafficFines = 'todo_fines',
	TodoDamage = 'todo_damage',
	TodoLocation = 'todo_location',
	TodoFuel = 'todo_fuel',
	TodoInsurance = 'todo_insurance',
	TodoKeys = 'todo_keys',
	TodoCost = 'todo_costs',
	TodoSafetyReport = 'todo_safetyreport',
	TodoRecovery = 'todo_recovery'
}

export enum NotificationGroup {
	Alert = 'alert',
	Todo = 'todo'
}

export default NotificationSetting;
