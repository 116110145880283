import axios, { AxiosError, AxiosResponse } from 'axios';

import Stats from '@/models/stats';
import { ErrorResponse, getAuthHeader } from '../helper';

function buildQuery(start: string, end: string, batch?: string | null): string {
	let query = `?`;
	query += `start=${start}&end=${end}`;
	if (batch) {
		query += `&batch=${batch}`;
	}
	return query;
}

export const FetchStats = async (start: string, end: string, batch?: string | null): Promise<Stats | ErrorResponse> => {
	const auth = getAuthHeader();
	const url = `/partner/stats` + buildQuery(start, end, batch);
	return await axios
		.get(url, auth)
		.then((res: AxiosResponse) => {
			return new Stats(res.data);
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};

export const DownloadStatsFile = async (start: string, end: string, batch?: string | null): Promise<string | ErrorResponse> => {
	const auth = getAuthHeader();
	auth.responseType = 'blob';
	const url = `/partner/stats/download` + buildQuery(start, end, batch);

	return await axios
		.get(url, auth)
		.then((res: AxiosResponse) => {
			return URL.createObjectURL(res.data);
		})
		.catch(async (err: AxiosError) => {
			const error = JSON.parse(await (err.response?.data as Blob).text()); // convert blob to text to json
			return new ErrorResponse(error);
		});
};
