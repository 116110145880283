<template>
	<v-snackbar
		v-model="show"
		:color="colorPicked"
		:timeout="snackTime"
		:top="position == 'top'"
		:centered="position == 'center'"
		:bottom="position == 'bottom'"
		transition="slide-x-transition"
		elevation="15"
	>
		<div class="heading-bold ma-2 text-center">
			<span class="snack-text"> {{ message }} </span>
		</div>
	</v-snackbar>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
	name: 'SnackBar',

	data() {
		return {
			show: false,
			position: '',
			message: '',
			color: '',
			time: 0,
			type: ''
		};
	},

	created() {
		this.$store.subscribe((mutation, state) => {
			if (mutation.type === 'showSnackbar') {
				this.message = state.snackbar.content;
				this.type = state.snackbar.type;
				this.position = state.snackbar.position;
				this.color = state.snackbar.color;
				this.time = state.snackbar.time;
				this.show = true;
			}
		});
	},

	computed: {
		colorPicked(): string {
			if (this.color) {
				return this.color;
			}
			switch (this.type) {
				case 'success':
					return 'primary';
				case 'error':
					return 'red lighten-1';
				default:
					return 'primary';
			}
		},

		snackTime(): number {
			return this.time > 0 ? this.time : 3500;
		}
	}
});
</script>

<style scoped>
.snack-text {
	font-size: 1.1rem;
}
</style>
