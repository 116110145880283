import Vue from 'vue';
import VueRouter, { Route, NavigationGuardNext } from 'vue-router';
import store from '@/store';
import i18n from '@/i18n';

import { Routes } from './routes';
import RoutesNames from './routesNames';

import { actionAllowed, tokenValidityCheck } from '@/utils';
import Api, { ErrorResponse } from '@/api';

Vue.use(VueRouter);

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: Routes
});

const anonymousOnlyGuard = (to: Route, from: Route, next: NavigationGuardNext): boolean => {
	if (to.matched.some((record) => record.meta.anonymousOnly)) {
		const isAnonymous = !store.state.user.is_logged_in;
		if (!isAnonymous) {
			next({
				name: RoutesNames.home
			});
			return false;
		}
	}
	return true;
};

const requiresAuthGuard = (to: Route, from: Route, next: NavigationGuardNext): boolean => {
	if (to.matched.some((record) => record.meta.requiresAuth)) {
		const isLoggedIn = store.state.user.is_logged_in;
		if (!isLoggedIn) {
			next({
				name: RoutesNames.authLogin,
				query: { redirect: to.fullPath }
			});
			return false;
		}
	}
	return true;
};

const requiresPermissionGuard = (to: Route, from: Route, next: NavigationGuardNext): boolean => {
	if (to.matched.some((record) => record.meta.perm)) {
		const isAllowed = to.meta?.perm === undefined || actionAllowed(to.meta?.perm);
		if (!isAllowed) {
			next(from.fullPath);
			const vm = new Vue();
			vm.$snackbar.showMessage({
				content: i18n.t('alert.error.no_permission'),
				type: 'error',
				time: 2000,
				position: 'center'
			});
			return false;
		}
	}
	return true;
};

const passiveAuth = async (): Promise<void> => {
	const isLoggedIn = store.state.user.is_logged_in;
	if (!isLoggedIn) {
		//try to recover auth from token
		const jwt = localStorage.getItem('USER_TOKEN');
		if (jwt !== null && tokenValidityCheck()) {
			const user_id = JSON.parse(atob(jwt.split('.')[1])).sub;

			const user = await Api.user.get(user_id, ['batches']);
			if (user instanceof ErrorResponse) {
				localStorage.removeItem('USER_TOKEN');
				return;
			}

			const partners = await Api.partner.list(0, 1, undefined, undefined, ['batches', 'districts:min']);
			if (partners instanceof ErrorResponse || partners.total_entries < 1) {
				localStorage.removeItem('USER_TOKEN');
				return;
			}

			user.is_logged_in = true;
			store.commit('storeUser', user);

			const partner = partners.entries[0];
			store.commit('storePartner', partner);
			return;
		}
	}
	return;
};

const homeRedirect = (to: Route, from: Route, next: NavigationGuardNext): void => {
	if (to.name == RoutesNames.home) {
		if (actionAllowed('partnerstats:list')) {
			return next();
		} else if (actionAllowed('partnertodo:list')) {
			return next({ name: RoutesNames.todo });
		} else {
			return next();
		}
	}
};

router.beforeEach(async (to, from, next) => {
	await passiveAuth();

	if (!anonymousOnlyGuard(to, from, next)) {
		return;
	}
	if (!requiresAuthGuard(to, from, next)) {
		return;
	}
	if (!requiresPermissionGuard(to, from, next)) {
		return;
	}

	homeRedirect(to, from, next);

	next(); // make sure to always call next()!
});

export { RoutesNames };
export default router;
