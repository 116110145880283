import { VApp } from 'vuetify/lib/components/VApp';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VMain } from 'vuetify/lib/components/VMain';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.dataReady)?_c(VApp,[_c(VOverlay,{attrs:{"opacity":".6","z-index":"99","value":_vm.langLoading,"dark":""}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"128"}})],1),(_vm.USER.is_logged_in)?_c('app-bar-main'):_vm._e(),(_vm.USER.is_logged_in)?_c('drawer-menu'):_vm._e(),_c(VMain,{staticClass:"bg-color"},[_c('router-view'),_c('snack-bar')],1),_c(VFooter,{attrs:{"fixed":"","elevation":"6","app":""}},[_c(VRow,{attrs:{"width":"100vw"}},[_c(VCol,{staticClass:"text-center pb-0 pb-sm-3",attrs:{"cols":"12","offset-sm":"3","sm":"6"}},[_c('span',{staticClass:"heading-bold mx-auto"},[_vm._v("© "+_vm._s(new Date().getFullYear())+" - 123-Transporter")])]),_c(VCol,{staticClass:"text-center text-sm-end pt-1 pt-sm-3",attrs:{"cols":"12","sm":"3"}},[_c('small',{staticClass:"grey--text text--lighten-1"},[_vm._v(_vm._s(_vm.today_date)+" "+_vm._s(_vm.today_time))])])],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }