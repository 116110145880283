enum routesNames {
	home = 'Home',
	data = 'Content',
	stats = 'Stats',
	accounting = 'Accounting',

	user = 'Users',
	userCreate = 'UserCreate',
	userEdit = 'UserEdit',
	userNotify = 'UserNotify',

	userLicenseCreate = 'UserLicenseCreate',

	truck = 'Trucks',
	truckMap = 'TruckMap',
	truckCreate = 'TruckCreate',
	truckEdit = 'TruckEdit',

	model = 'Model',
	modelCreate = 'ModelCreate',
	modelEdit = 'ModelEdit',

	tourList = 'TourList',

	authLogin = 'Login',

	todo = 'Todo',
	todoWorkorder = 'TodoWorkorder',
	todoArchive = 'TodoArchive',

	helpCenter = 'HelpCenter',
	threadDetails = 'ThreadDetails'
}

export default routesNames;
