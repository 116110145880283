<template>
	<v-dialog
		v-model="show"
		persistent
		max-width="450px"
	>
		<v-card class="pa-1">
			<v-card-title :class="`${color}--text`">
				<h3
					class="heading-bold mt-1"
					v-html="titleDisplay"
				/>
			</v-card-title>
			<v-card-text
				class="mt-3 main-text"
				v-html="message"
			/>
			<v-card-actions>
				<v-row>
					<v-col cols="6">
						<v-btn
							elevation="0"
							block
							:color="emphasizeCancel ? 'primary' : ''"
							@click="clickNo"
						>
							{{ $t('actions.no') }}
						</v-btn>
					</v-col>
					<v-col cols="6">
						<v-btn
							elevation="0"
							block
							:color="emphasizeCancel ? '' : 'primary'"
							@click="clickYes"
						>
							{{ $t('actions.yes') }}
						</v-btn>
					</v-col>
				</v-row>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
	name: 'ConfirmDialog',
	props: {
		message: {
			type: String,
			required: true
		},
		title: {
			type: String
		},
		color: {
			type: String,
			default: 'primary'
		},
		emphasizeCancel: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			show: true as boolean
		};
	},

	computed: {
		titleDisplay(): string {
			return this.title ? this.title : (this.$t('actions.confirm_title') as string);
		}
	},

	methods: {
		clickYes(): void {
			this.show = false;
			this.$emit('click', true);
		},
		clickNo(): void {
			this.show = false;
			this.$emit('click', false);
		}
	}
});
</script>

<style scoped>
.main-text {
	font-size: 1rem;
}
</style>
