import AModel from './AModel';
import Partner from './partner';
import User from './user';
import Truck from './truck';
import Downtime from './downtime';
import Todo from './todo';

class Workorder extends AModel {
	id: string;

	downtime: Downtime | string | null;
	partner: Partner | string | null;
	truck: Truck | string | null;
	user: User | string | null;
	todos: Todo[];
	pictures: {
		before: string[];
		after: string[];
	};
	addons: string[];

	time_work_start: string;
	time_work_end: string;
	time_expire: string | null;

	freetext: string | null;

	constructor(opt?: Partial<Workorder>) {
		super();

		//default values (all props)
		this.id = '';
		this.time_work_start = '';
		this.time_work_end = '';
		this.time_expire = null;

		this.downtime = null;
		this.partner = null;
		this.truck = null;
		this.user = null;
		this.todos = [];
		this.addons = [];

		this.pictures = {
			before: [],
			after: []
		};

		this.freetext = '';

		if (opt) {
			//sanitize the input to keep only the fields that are needed for this model
			const sanitized = Object.assign({}, ...Object.keys(this).map((key) => ({ [key]: (key in opt ? opt : this)[key] })));

			//assign values passed to the constructor
			Object.assign<Workorder, Partial<Workorder>>(this, sanitized);

			if (this.partner && typeof this.partner != 'string') {
				this.partner = new Partner(this.partner);
			}
			if (this.truck && typeof this.truck != 'string') {
				this.truck = new Truck(this.truck);
			}
			if (this.user && typeof this.user != 'string') {
				this.user = new User(this.user);
			}
			if (this.downtime && typeof this.downtime != 'string') {
				this.downtime = new Downtime(this.downtime);
			}
			if (this.todos.length > 0) {
				this.todos = this.todos.map((g) => {
					return new Todo(g);
				});
			}
		}
	}

	copy(): Workorder {
		const copy = new Workorder(this.toObject());
		return copy;
	}

	toDTO(): Record<string, unknown> {
		const object = this.toObject();

		const dto = {
			id: object.id,
			truck: object.truck,
			user: object.user,
			todos: object.todos,
			addons: object.addons,
			freetext: object.freetext,
			time_start: null as string | null,
			time_end: null as string | null
		};

		if (this.truck instanceof Truck) {
			dto.truck = this.truck.id;
		}
		if (this.user instanceof User) {
			dto.user = this.user.id;
		}
		if (this.downtime instanceof Downtime) {
			dto.time_start = this.downtime.time_start;
			dto.time_end = this.downtime.time_end;
		}
		if (this.todos.length > 0) {
			dto.todos = this.todos.map((g) => g.id);
		}

		return dto;
	}
}

export default Workorder;
