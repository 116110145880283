/* eslint-disable @typescript-eslint/no-explicit-any */
export default abstract class AModel {
	[index: string]: any;

	toObject(): Record<string, unknown> {
		const obj: { [index: string]: any } = {};
		const keys = Object.keys(this);
		for (const k in keys) {
			const prop = this[keys[k]];
			if (prop instanceof AModel) {
				obj[keys[k]] = prop.toObject();
			} else if (prop instanceof Array) {
				obj[keys[k]] = prop.map((e) => e);
			} else if (prop instanceof Object) {
				const tmp: { [index: string]: any } = {};
				for (const i in prop) {
					tmp[i] = prop[i];
				}
				obj[keys[k]] = tmp;
			} else if (prop === '') {
				obj[keys[k]] = null;
			} else {
				obj[keys[k]] = prop;
			}
		}

		return obj;
	}

	toDTO(): Record<string, unknown> {
		return this.toObject();
	}
}
