import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"persistent":true,"max-width":"450px"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,{staticClass:"pa-1"},[(_vm.title)?_c(VCardTitle,{class:`${_vm.color}--text`},[_c('h3',{staticClass:"heading-bold mt-1",domProps:{"innerHTML":_vm._s(_vm.title)}})]):_vm._e(),_c(VCardText,{staticClass:"mt-3",domProps:{"innerHTML":_vm._s(_vm.message)}}),_c(VCardActions,{staticClass:"mt-2"},[_c(VBtn,{staticClass:"mx-auto",attrs:{"elevation":"0","color":"primary"},on:{"click":_vm.clickOK}},[_vm._v(" "+_vm._s(_vm.$t('actions.ok'))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }