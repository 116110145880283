import axios, { AxiosError, AxiosResponse } from 'axios';
import { ErrorResponse, getAuthHeader } from '../helper';

export interface AuthResponse {
	access_token: string;
	access_token_expires: number;
	refresh_token: string;
	refresh_token_expires: number;
}

export const Login = async (user: string, password: string): Promise<AuthResponse | ErrorResponse> => {
	const url = '/auth';

	return await axios
		.post(url, { user, password })
		.then((res: AxiosResponse) => {
			return res.data as AuthResponse;
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};

export const Logout = async (): Promise<boolean | ErrorResponse> => {
	const url = '/auth';
	const auth = getAuthHeader();
	return await axios
		.delete(url, auth)
		.then(() => {
			return true;
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};

export const ChangePassword = async (old_password: string, new_password: string): Promise<boolean | ErrorResponse> => {
	const url = '/auth/password';
	const auth = getAuthHeader();

	return await axios
		.patch(url, { old_password, new_password }, auth)
		.then(() => {
			return true;
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};
