import AModel from './AModel';
import Batch from './batch';
import Partner from './partner';
import UserGroup from './usergroup';

class User extends AModel {
	id: string;
	fullname: string;
	firstname: string;
	lastname: string;
	company: string;
	gender: string;

	date_of_birth: string;
	email: string;
	phone: string;
	avatar: string;

	drivers_license: DriverLicenseState;

	address: {
		country: string;
		postcode: string;
		city: string;
		street: string;
		houseno: string;
		addition: string;
	};

	groups: UserGroup[];
	batches: Batch[];
	partner: string | Partner | null;

	is_logged_in: boolean;

	state: number;

	constructor(opt?: Partial<User>) {
		super();

		//default values (all props)
		this.id = '';
		this.fullname = '';
		this.firstname = '';
		this.lastname = '';
		this.company = '';
		this.gender = '';

		this.date_of_birth = '';
		this.email = '';
		this.phone = '';
		this.avatar = '';

		this.drivers_license = DriverLicenseState.Missing;

		this.address = {
			country: '',
			postcode: '',
			city: '',
			street: '',
			houseno: '',
			addition: ''
		};

		this.groups = [];
		this.batches = [];

		this.partner = null;

		this.is_logged_in = false;

		this.state = 1;

		if (opt) {
			//sanitize the input to keep only the fields that are needed for this model
			const sanitized = Object.assign({}, ...Object.keys(this).map((key) => ({ [key]: (key in opt ? opt : this)[key] })));

			//assign values passed to the constructor
			Object.assign<User, Partial<User>>(this, sanitized);

			if (this.partner && typeof this.partner != 'string') {
				this.partner = new Partner(this.partner);
			}

			if (this.groups.length > 0) {
				this.groups = this.groups.map((g) => {
					return new UserGroup(g);
				});
			}
			if (this.batches.length > 0) {
				this.batches = this.batches.map((g) => {
					return new Batch(g);
				});
			}
		}
	}

	copy(): User {
		const copy = new User(this.toObject());
		return copy;
	}

	toDTO(): Record<string, unknown> {
		const dto = this.toObject();
		delete dto.drivers_license;

		if (this.groups.length > 0) {
			dto.groups = this.groups.map((g) => g.id);
		}

		if (this.partner instanceof Partner) {
			dto.partner = this.partner.id;
		}

		return dto;
	}
}

export enum DriverLicenseState {
	Missing = 'MISSING',
	Pending = 'PENDING',
	NotOK = 'NOK',
	OK = 'OK'
}

export default User;
