import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VNavigationDrawer,{attrs:{"clipped":"","fixed":"","app":"","height":"100%"},scopedSlots:_vm._u([(false)?{key:"append",fn:function(){return [_c(VList,[_c(VListItem,{attrs:{"to":{ name: _vm.RoutesNames.helpCenter },"router":"","exact":""}},[_c(VListItemAction,[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-help-circle-outline")])],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"heading-bold"},[_vm._v(_vm._s(_vm.$t('menu.help_center')))])],1)],1)],1)]},proxy:true}:null],null,true),model:{value:(_vm.drawerMenu),callback:function ($$v) {_vm.drawerMenu=$$v},expression:"drawerMenu"}},[_c('h1',{staticClass:"text-center heading-bold hidden-sm-and-up primary--text pa-4 pb-0",staticStyle:{"font-size":"x-large"},domProps:{"innerHTML":_vm._s(_vm.title)}}),_c('div',{staticClass:"partner-info pa-4"},[(_vm.PARTNER.logo)?_c(VImg,{attrs:{"src":_vm.PARTNER.logo,"alt":_vm.PARTNER.name,"title":_vm.PARTNER.name,"contain":"","max-height":"150"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c(VRow,{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c(VProgressCircular,{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,false,4034393411)}):_c('h4',{staticClass:"text-center heading-bold",staticStyle:{"font-size":"x-large"}},[_vm._v(" "+_vm._s(_vm.PARTNER.name)+" ")])],1),_c(VList,_vm._l((_vm.itemsAllowed),function(item,i){return _c(VListItem,{key:i,attrs:{"to":item.to,"router":"","exact":""}},[_c(VListItemAction,[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(item.icon)+" ")])],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"heading-bold"},[_vm._v(_vm._s(item.title))])],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }