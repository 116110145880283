<template>
	<v-menu
		ref="menu"
		offset-y
		nudge-left="50%"
	>
		<template v-slot:activator="{ on, attrs }">
			<img
				style="width: 22px; opacity: 0.9"
				class="d-block"
				:src="getFlag()"
				v-bind="attrs"
				v-on="on"
			/>
		</template>
		<v-list class="py-0 my-0 mx-auto">
			<v-list-item
				v-for="country in countries"
				:key="country.value"
				class="lang-menu-item"
				@click.stop="localeClicked(country.value)"
			>
				<div
					class="d-flex align-center"
					@click.stop="localeClicked(country.value)"
				>
					<img
						style="width: 26px"
						:src="country.flag"
						class="mr-2 d-block"
					/>
					<span class="pl-2 country-text">{{ country.caption }}</span>
				</div>
			</v-list-item>
		</v-list>
	</v-menu>
</template>

<script lang="ts">
import mixins from 'vue-typed-mixins';
import LocaleHandler from '@/mixins/LocaleHandler';
import { Locales } from '@/i18n/locales';

interface ILocaleOption {
	value: string;
	caption: string;
	flag: string;
}

type VMenu = Vue & {
	isActive: boolean;
};

export default mixins(LocaleHandler).extend({
	name: 'LocaleSwitcher',

	mixins: [LocaleHandler],

	async created() {
		this.localeChosen = this.getStartingLocale;
	},

	data() {
		return {
			localeChosen: ''
		};
	},

	computed: {
		menu(): VMenu {
			return this.$refs.menu as VMenu;
		},

		currentLocale(): string {
			return this.$i18n.locale;
		},

		localeToBCP47(): string {
			return this.$i18n.locale.split('-')[0];
		},

		countries(): ILocaleOption[] {
			return [
				{
					value: `${Locales.EN}-${this.$buinfo.get().toUpperCase()}`,
					caption: this.$t('locale.english') as string,
					flag: '/assets/icons/flags/gb.svg'
				},
				{
					value: `${Locales.DE}-${this.$buinfo.get().toUpperCase()}`,
					caption: this.$t('locale.german') as string,
					flag: `/assets/icons/flags/${this.$buinfo.get() === 'at' ? 'at' : 'de'}.svg`
				},
				{
					value: `${Locales.CZ}-${this.$buinfo.get().toUpperCase()}`,
					caption: this.$t('locale.czech') as string,
					flag: `/assets/icons/flags/cz.svg`
				},
				{
					value: `${Locales.HU}-${this.$buinfo.get().toUpperCase()}`,
					caption: this.$t('locale.hungarian') as string,
					flag: `/assets/icons/flags/hu.svg`
				},
				{
					value: `${Locales.SK}-${this.$buinfo.get().toUpperCase()}`,
					caption: this.$t('locale.slovakian') as string,
					flag: `/assets/icons/flags/sk.svg`
				}
			];
		},

		getFlag() {
			return (): string | null => {
				const loc = this.localeChosen.split('-')[0];
				for (const i in this.countries) {
					if (this.countries[i].value.split('-')[0] === loc) {
						return this.countries[i].flag;
					}
				}
				return null;
			};
		}
	},

	methods: {
		async localeClicked(locale: string): Promise<void> {
			sessionStorage.setItem('lang', locale);
			this.localeChosen = locale;

			await this.loadLocaleMessagesAsync(locale);
			this.menu.isActive = false;
		}
	}
});
</script>

<style lang="sass">
.lang-menu-item
	cursor: pointer
	&:hover
		background-color: #e6e6e6

.country-text
	font-size: .80rem
	font-weight: 550
</style>
