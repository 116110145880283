<template>
	<v-card max-width="500px">
		<v-card-title>
			<h2 class="heading-bold primary--text my-2 mx-auto">{{ $t('actions.change_password') }}</h2>
		</v-card-title>
		<v-divider />

		<v-container class="px-6">
			<v-row
				no-gutters
				class="my-2"
			>
				<v-col
					cols="12"
					class="px-6"
				>
					<v-form ref="form">
						<v-text-field
							v-model="pwd_current"
							aria-autocomplete="current-password"
							:label="$t('user.password_current') + ' *'"
							:rules="[rules.required]"
							:type="!pwd_current_visible ? 'password' : 'text'"
							:append-icon="pwd_current_visible ? 'mdi-eye-off' : 'mdi-eye'"
							@click:append="() => (pwd_current_visible = !pwd_current_visible)"
							@blur="pwd_current_visible = false"
						/>
						<v-text-field
							v-model="pwd_new"
							aria-autocomplete="password"
							:label="$t('user.password_new') + ' *'"
							:rules="[rules.required]"
							:type="!pwd_new_visible ? 'password' : 'text'"
							:append-icon="pwd_new_visible ? 'mdi-eye-off' : 'mdi-eye'"
							@click:append="() => (pwd_new_visible = !pwd_new_visible)"
							@blur="pwd_new_visible = false"
						/>
						<v-text-field
							v-model="pwd2"
							aria-autocomplete="new-password"
							:label="$t('user.password_repeat') + ' *'"
							:rules="[rules.required]"
							:type="!pwd2_visible ? 'password' : 'text'"
							:append-icon="pwd2_visible ? 'mdi-eye-off' : 'mdi-eye'"
							@click:append="() => (pwd2_visible = !pwd2_visible)"
							@blur="pwd2_visible = false"
						/>
					</v-form>
					<v-alert
						v-show="errorAlreadyTaken"
						dense
						color="red"
						outlined
					>
						{{ $t('alert.error.password_wrong') }}
					</v-alert>
					<v-alert
						v-show="errorSamePw"
						dense
						color="red"
						outlined
					>
						{{ $t('alert.error.password_is_current') }}
					</v-alert>
				</v-col>
			</v-row>
			<v-card-actions class="px-5">
				<v-btn
					block
					color="primary"
					elevation="0"
					class="white--text mt-2"
					:disabled="!confirm_ok || !pwd_current || !pwd_new"
					@click="changePwd"
				>
					{{ $t('actions.save') }}
				</v-btn>
			</v-card-actions>
		</v-container>
	</v-card>
</template>
<script lang="ts">
import mixins from 'vue-typed-mixins';

import rules from '@/mixins/formRules';
import Vue from 'vue';
import Api, { ErrorResponse } from '@/api';

export default mixins(rules).extend({
	name: 'ChangePassword',

	data() {
		return {
			errorAlreadyTaken: false,
			errorSamePw: false,
			pwd_current_visible: false,
			pwd_new_visible: false,
			pwd2_visible: false,
			pwd_current: '',
			pwd_new: '',
			pwd2: ''
		};
	},

	watch: {
		pwd_current() {
			this.errorAlreadyTaken = false;
			this.errorSamePw = false;
		},

		pwd_new() {
			this.errorSamePw = false;
		}
	},

	computed: {
		confirm_ok(): boolean {
			return this.pwd_new == this.pwd2;
		},

		sameAsCurrent(): boolean {
			return this.pwd_current == this.pwd_new;
		},

		formReset(): Vue & { resetValidation: () => boolean } {
			return this.$refs.form as Vue & { resetValidation: () => boolean };
		}
	},

	methods: {
		async changePwd() {
			this.errorAlreadyTaken = false;
			this.errorSamePw = false;

			if (this.sameAsCurrent) {
				this.errorSamePw = true;
				return;
			}

			const resv2 = await Api.auth.changePassword(this.pwd_current, this.pwd_new);

			if (resv2 instanceof ErrorResponse) {
				this.$snackbar.showMessage({
					content: this.$t('alert.error.general'),
					type: 'error',
					position: 'center'
				});
				return;
			}

			this.$snackbar.showMessage({
				content: this.$t('alert.success.password_changed'),
				type: 'success',
				time: 1500,
				position: 'center'
			});
			this.pwd_current = '';
			this.pwd_new = '';
			this.pwd2 = '';
			this.$emit('close');
			this.formReset.resetValidation();
		}
	}
});
</script>
