<template>
	<div>
		<v-app-bar
			clipped-left
			fixed
			app
		>
			<v-app-bar-nav-icon @click.stop="triggerDrawer" />

			<v-img
				max-width="40"
				class="mx-2"
				src="/assets/logo/square.svg"
				style="cursor: pointer"
				@click.stop="gotoHome"
			/>

			<v-toolbar-title
				class="heading-bold primary--text toolbar-title d-none d-sm-block ml-2"
				v-text="title"
				style="cursor: pointer"
				@click.stop="gotoHome"
			/>

			<v-spacer />

			<v-tooltip
				bottom
				v-if="!USER.is_logged_in"
			>
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						class="mr-2"
						icon
						v-bind="attrs"
						v-on="on"
						color="primary"
						@click.stop="toLoginPage"
					>
						<v-icon>mdi-login</v-icon>
					</v-btn>
				</template>
				<span>{{ $t('actions.login') }}</span>
			</v-tooltip>

			<v-menu
				v-if="USER.is_logged_in"
				bottom
				left
				offset-y
			>
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						class="mr-2"
						icon
						v-bind="attrs"
						v-on="on"
						color="primary"
					>
						<v-avatar
							v-if="avatar"
							size="38"
						>
							<v-img
								:src="avatar"
								alt="user-avatar"
							/>
						</v-avatar>

						<v-icon v-else>mdi-account</v-icon>
					</v-btn>
				</template>

				<v-list class="py-0 my-0 mx-auto">
					<v-list-item
						class="menu-account"
						@click.stop="avatarDialog = true"
					>
						<v-list-item-title>
							<v-icon
								class="pr-2 pb-1"
								color="secondary"
							>
								mdi-account-circle
							</v-icon>
							<span class="account-menu-text">{{ $t('actions.change_avatar') }}</span>
						</v-list-item-title>
					</v-list-item>
					<v-list-item
						class="menu-account"
						@click.stop="changePasswordDialog = true"
					>
						<v-list-item-title>
							<v-icon
								class="pr-2 pb-1"
								color="secondary"
							>
								mdi-lock-question
							</v-icon>
							<span class="account-menu-text">{{ $t('actions.change_password') }}</span>
						</v-list-item-title>
					</v-list-item>
					<v-list-item
						class="menu-account"
						@click.stop="logout"
					>
						<v-icon
							class="pr-1"
							color="secondary"
						>
							mdi-logout
						</v-icon>
						<v-list-item-title @click.stop="logout">
							<a
								class="pl-1 account-menu-text"
								href="#"
							>
								{{ $t('actions.logout') }}
							</a>
						</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>

			<v-dialog
				v-model="changePasswordDialog"
				:fullscreen="isMobile"
				max-width="500px"
			>
				<change-password @close="changePasswordDialog = false" />
			</v-dialog>

			<v-dialog
				v-model="avatarDialog"
				:fullscreen="isMobile"
				max-width="500px"
			>
				<avatar-uploader
					:user="USER.id"
					:current-avatar="USER.avatar"
					@img="avatar_img = $event"
					@close="avatarDialog = false"
				/>
			</v-dialog>

			<locale-switcher class="mx-auto text-center" />
		</v-app-bar>
	</div>
</template>

<script lang="ts">
import { mapState } from 'vuex';

import ChangePassword from '@/components/user/ui/ChangePassword.vue';
import LocaleSwitcher from '@/components/general/ui/LocaleSwitcher.vue';
import AvatarUploader from '@/components/user/ui/AvatarUploader.vue';
import routesNames from '@/router/routesNames';
import Vue from 'vue';
import { TranslateResult } from 'vue-i18n';

export default Vue.extend({
	name: 'AppBarMain',

	components: { LocaleSwitcher, ChangePassword, AvatarUploader },

	created(): void {
		this.$store.commit('drawerMenuState', !this.isMobile);
	},

	data() {
		return {
			avatar_img: '',

			avatarTrigger: false,

			avatarDialog: false,
			changePasswordDialog: false
		};
	},

	computed: {
		title(): TranslateResult {
			return this.$t('document_title.fss');
		},

		isMobile(): boolean {
			return this.$vuetify.breakpoint.xs;
		},

		avatar(): string | boolean {
			return this.avatar_img ? this.avatar_img : this.USER.avatar;
		},

		...mapState({
			USER: 'user',
			PARTNER: 'partner',
			DRAWER: 'drawerMenu'
		})
	},

	methods: {
		triggerDrawer(): void {
			this.$store.commit('drawerMenuState', !this.DRAWER);
		},

		gotoHome(): void {
			if (this.$route.name != routesNames.home) {
				this.$router.push({ name: routesNames.home });
			}
		},

		toLoginPage(): void {
			if (this.$route.name != routesNames.authLogin) {
				this.$router.push({ name: routesNames.authLogin });
			}
		},

		logout(): void {
			this.$store.dispatch('USER_LOGOUT');
		}
	}
});
</script>

<style scoped lang="sass">
.toolbar-title
	font-size: 1.5rem

.account-menu-text
	font-size: .9rem
	color: grey
	text-decoration: none

.menu-account
	cursor: pointer
	&:hover
		background-color: #e6e6e6
</style>
