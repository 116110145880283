import i18n from '@/i18n';
import Vue from 'vue';

export interface ICountry {
	name: string;
	code: string;
}
export interface IBaseCountry {
	code: string;
	zone: number | null;
	pcp: string;
}

export const ctyList = [
	{ code: 'BE', zone: 3, pcp: '\\d{4}' },
	{ code: 'BG', zone: 3, pcp: '\\d{4}' },
	{ code: 'CZ', zone: 1, pcp: '[0-9]{3} [0-9]{2}|[0-9]{5}' },
	{ code: 'DE', zone: 2, pcp: '\\d{5}' },
	{ code: 'DK', zone: 3, pcp: '\\d{5}' },
	{ code: 'EE', zone: null, pcp: '\\d{5}' },
	{ code: 'IE', zone: null, pcp: '.*' },
	{ code: 'ES', zone: 3, pcp: '\\d{5}' },
	{ code: 'FR', zone: 3, pcp: '\\d{5}' },
	{ code: 'HR', zone: 2, pcp: '\\d{5}' },
	{ code: 'IT', zone: 2, pcp: '\\d{5}' },
	{ code: 'CY', zone: null, pcp: '.*' },
	{ code: 'LV', zone: null, pcp: '[L]{1}[V]{1}[-]([0-9]){4}' },
	{ code: 'LT', zone: null, pcp: '((?:LT)[\\-])?(\\d{5})' },
	{ code: 'LU', zone: 3, pcp: '((?:L)[\\-])?(\\d{4})' },
	{ code: 'HU', zone: 1, pcp: '\\d{4}' },
	{ code: 'MT', zone: null, pcp: '[A-Z]{3} [0-9]{4}|[A-Z]{2}[0-9]{2}|[A-Z]{2} [0-9]{2}|[A-Z]{3}[0-9]{4}|[A-Z]{3}[0-9]{2}|[A-Z]{3} [0-9]{2}' },
	{ code: 'NL', zone: 3, pcp: '[0-9]{4} [A-Z]{2}|[0-9]{4}[A-Z]{2}' },
	{ code: 'AT', zone: null, pcp: '\\d{4}' },
	{ code: 'PL', zone: 2, pcp: '[0-9]{2}[-]([0-9]){3}' },
	{ code: 'PT', zone: 3, pcp: '\\d{4}((-)\\d{3})' },
	{ code: 'RO', zone: 3, pcp: '\\d{6}' },
	{ code: 'SI', zone: 1, pcp: '\\d{4}' },
	{ code: 'SK', zone: 1, pcp: '(\\d{3} \\d{2})|\\d{5}' },
	{ code: 'FI', zone: 3, pcp: '\\d{5}' },
	{ code: 'SE', zone: 3, pcp: '(\\d{3} \\d{2})' },
	{ code: 'GR', zone: 3, pcp: '(\\d{3}) \\d{2}|\\d{5}' },
	{ code: 'CH', zone: 2, pcp: '\\d{4}' },
	{ code: 'ME', zone: 2, pcp: '\\d{5}' },
	{ code: 'RS', zone: null, pcp: '\\d{5}' },
	{ code: 'MK', zone: null, pcp: '\\d{4}' },
	{ code: 'AL', zone: null, pcp: '\\d{4}' }
] as IBaseCountry[];

const CountryList = Vue.extend({
	computed: {
		countryList(): Array<ICountry> {
			const lst: Array<ICountry> = ctyList.map((cty: IBaseCountry) => {
				return {
					name: i18n.t('countries.' + cty.code.toLowerCase()) as string,
					code: cty.code
				};
			});

			lst.sort((a: ICountry, b: ICountry) => (a.name > b.name ? 1 : -1));
			lst.sort((a: ICountry, b: ICountry) => (b && a.code == 'AT' ? -1 : 1));

			return lst;
		},

		countryCodeList(): string[] {
			return this.countryList.map((c: ICountry) => {
				return c.code;
			});
		}
	}
});

export default CountryList;
