import AModel from './AModel';

class Batch extends AModel {
	id: string;
	name: string;
	size: number;
	active_start: string;
	active_end: string;

	constructor(opt?: Partial<Batch>) {
		super();

		//default values (all props)
		this.id = '';
		this.name = '';
		this.size = 0;
		this.active_start = '';
		this.active_end = '';

		if (opt) {
			//sanitize the input to keep only the fields that are needed for this model
			const sanitized = Object.assign({}, ...Object.keys(this).map((key) => ({ [key]: (key in opt ? opt : this)[key] })));

			//assign values passed to the constructor
			Object.assign<Batch, Partial<Batch>>(this, sanitized);
		}
	}

	copy(): Batch {
		const copy = new Batch(this.toObject());
		return copy;
	}
}

export default Batch;
