import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VCard,{attrs:{"max-width":"500px"}},[_c(VCardTitle,[_c('h2',{staticClass:"heading-bold primary--text my-2 mx-auto"},[_vm._v(_vm._s(_vm.$t('actions.change_avatar')))])]),_c(VDivider),_c(VContainer,{staticClass:"px-6"},[_c(VRow,{staticClass:"my-2",attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"px-6",attrs:{"cols":"12"}},[(_vm.showAvatar)?_c('div',{staticClass:"text-center"},[_c(VImg,{attrs:{"src":_vm.currentImg,"height":"120px","alt":"avatar-uploaded","contain":""}})],1):_vm._e(),_c('avatar-cropper',{attrs:{"upload-handler":_vm.uploadAvatar,"labels":{
						submit: _vm.$t('actions.ok'),
						cancel: _vm.$t('actions.cancel')
					},"inline":""},model:{value:(_vm.cropper),callback:function ($$v) {_vm.cropper=$$v},expression:"cropper"}})],1)],1),_c(VCardActions,{staticClass:"px-5"},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VBtn,{attrs:{"block":"","text":"","color":"primary"},on:{"click":_vm.openCropper}},[_vm._v(" "+_vm._s(_vm.$t('actions.upload'))+" ")])],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VBtn,{staticClass:"white--text mt-2",attrs:{"block":"","color":"primary","elevation":"0","loading":_vm.loading},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t('actions.cancel'))+" ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }