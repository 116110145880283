<template>
	<!-- Can't leave this dialog  100% width honeslty -->
	<v-dialog
		v-model="show"
		:max-width="dialogMaxWidth"
		persistent
	>
		<v-card class="pa-1">
			<v-card-title :class="`${color}--text`">
				<h3
					class="heading-bold primary--text mt-1"
					v-html="title"
				/>
			</v-card-title>
			<v-card-text class="mt-3">
				<span v-html="message" />
			</v-card-text>
			<v-card-text>
				<v-form ref="form">
					<v-textarea
						v-if="isTextArea"
						v-model="value"
						:label="label"
						:rules="required ? [rules.required] : []"
					/>
					<v-file-input
						v-else-if="isFilePicker"
						v-model="file"
						accept=".pdf"
						color="primary"
						:placeholder="label"
						:rules="required ? [rules.required] : []"
						:show-size="1000"
					>
						<template v-slot:selection="{ index, text }">
							<v-chip
								v-if="index < 2"
								color="deep-purple accent-4"
								dark
								label
								small
							>
								{{ text }}
							</v-chip>
						</template>
					</v-file-input>

					<v-checkbox
						v-else-if="isCheckbox"
						v-model="value"
						:label="label"
					/>

					<v-text-field
						v-else
						v-model="value"
						:label="label"
						:type="type"
						:rules="required ? [rules.required] : []"
					/>
				</v-form>
			</v-card-text>
			<v-card-actions>
				<v-row>
					<v-col cols="6">
						<v-btn
							elevation="0"
							block
							@click="clickCancel"
						>
							{{ $t('actions.cancel') }}
						</v-btn>
					</v-col>
					<v-col cols="6">
						<v-btn
							elevation="0"
							block
							color="primary"
							:disabled="disableSaveButton"
							@click="clickOk"
						>
							{{ btnOkText }}
						</v-btn>
					</v-col>
				</v-row>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script lang="ts">
import mixins from 'vue-typed-mixins';
import { VForm } from '@/types';
import rules from '@/mixins/formRules';

export default mixins(rules).extend({
	name: 'PromptDialog',
	props: {
		title: {
			type: String,
			required: true
		},
		message: {
			type: String,
			required: true
		},
		type: {
			type: String,
			required: false,
			default: 'text'
		},
		default: {
			type: String,
			required: false
		},
		required: {
			type: Boolean,
			required: false,
			default: true
		},
		label: {
			type: String,
			required: false,
			default: ''
		},
		color: {
			type: String,
			default: 'primary'
		},
		okText: {
			type: String,
			default: null
		},
		dialogMaxWidth: {
			type: String,
			default: '700'
		},
		activateDisableAction: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			show: true,
			value: '',
			file: null as null | File
		};
	},

	mounted() {
		if (this.default) {
			this.value = this.default;
		}
	},
	computed: {
		btnOkText(): string {
			if (this.okText !== null) {
				return this.okText;
			}
			return this.$t('actions.ok') as string;
		},

		form(): VForm {
			return this.$refs.form as VForm;
		},
		isTextArea(): boolean {
			return this.type == 'textarea';
		},
		isFilePicker(): boolean {
			return this.type == 'file' || this.type == 'files';
		},
		isCheckbox(): boolean {
			return this.type == 'checkbox';
		},
		disableSaveButton(): boolean {
			return this.activateDisableAction && !this.value;
		}
	},

	methods: {
		clickOk(): void {
			if (this.form.validate()) {
				this.show = false;

				let emitValue: string | File | boolean | null = this.value;
				if (this.isFilePicker) {
					emitValue = this.file;
				} else if (this.isCheckbox) {
					emitValue = this.value == '' ? false : this.value;
				}

				this.$emit('click', emitValue);
			}
		},
		clickCancel(): void {
			this.show = false;
			this.$emit('click', undefined);
		}
	}
});
</script>
