import axios, { AxiosError, AxiosResponse } from 'axios';

import { ListResponse, ListRequestOrder, ListRequestFilter, buildListQuery, ErrorResponse, getAuthHeader } from '../helper';
import Accounting from '@/models/accounting';

export const FetchAccountingList = async (
	page: number,
	page_size: number,
	filter?: ListRequestFilter,
	order?: ListRequestOrder,
	expands?: string[]
): Promise<ListResponse<Accounting> | ErrorResponse> => {
	const auth = getAuthHeader();
	const url = `/partner/accounting` + buildListQuery(page, page_size, filter, order, expands);
	return await axios
		.get(url, auth)
		.then((res: AxiosResponse) => {
			return new ListResponse<Accounting>(Accounting, res.data);
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};

export const DownloadAccountingFile = async (id: string): Promise<string | ErrorResponse> => {
	const auth = getAuthHeader();
	auth.responseType = 'blob';
	const url = `/partner/accounting/${id}/download`;

	return await axios
		.get(url, auth)
		.then((res: AxiosResponse) => {
			return URL.createObjectURL(res.data);
		})
		.catch(async (err: AxiosError) => {
			const error = JSON.parse(await (err.response?.data as Blob).text()); // convert blob to text to json
			return new ErrorResponse(error);
		});
};
