import AModel from './AModel';
import Batch from './batch';

class Accounting extends AModel {
	id: string;
	number: string;
	type: AccountingType;
	timeframe: string;
	value: number;

	batch: string | Batch | null;

	constructor(opt?: Partial<Accounting>) {
		super();

		//default values (all props)
		this.id = '';
		this.number = '';
		this.type = AccountingType.CreditNote;
		this.timeframe = '';
		this.value = 0;
		this.batch = null;

		if (opt) {
			//sanitize the input to keep only the fields that are needed for this model
			const sanitized = Object.assign({}, ...Object.keys(this).map((key) => ({ [key]: (key in opt ? opt : this)[key] })));

			//assign values passed to the constructor
			Object.assign<Accounting, Partial<Accounting>>(this, sanitized);

			if (this.batch && typeof this.batch != 'string') {
				this.batch = new Batch(this.batch);
			}
		}
	}

	copy(): Accounting {
		const copy = new Accounting(this.toObject());
		return copy;
	}
}

export enum AccountingType {
	CreditNote = 'CREDITNOTE',
	MonthCompensation = 'COMP',
	YearCompensation = 'YEAR'
}

export default Accounting;
