import axios, { AxiosError, AxiosResponse } from 'axios';

import { ListResponse, ListRequestOrder, ListRequestFilter, buildListQuery, ErrorResponse, stringifyExpands, getAuthHeader } from '../helper';
import Workorder from '@/models/workorder';
import { TruckTelemetryStatus } from '@/models/truck';

export const FetchWorkorderList = async (
	page: number,
	page_size: number,
	filter?: ListRequestFilter,
	order?: ListRequestOrder,
	expands?: string[]
): Promise<ListResponse<Workorder> | ErrorResponse> => {
	const auth = getAuthHeader();
	const url = `/partner/todo/workorder` + buildListQuery(page, page_size, filter, order, expands);

	return await axios
		.get(url, auth)
		.then((res: AxiosResponse) => {
			return new ListResponse<Workorder>(Workorder, res.data);
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};

export const GetOneWorkorder = async (id: string, expands?: string[]): Promise<Workorder | ErrorResponse> => {
	const auth = getAuthHeader();
	const url = `/partner/todo/workorder/${id}` + stringifyExpands(expands);

	return await axios
		.get(url, auth)
		.then((res: AxiosResponse) => {
			return new Workorder(res.data);
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};

export const CreateWorkorder = async (workorder: Workorder, expands?: string[]): Promise<Workorder | ErrorResponse> => {
	const auth = getAuthHeader();
	const url = `/partner/todo/workorder` + stringifyExpands(expands);

	return await axios
		.post(url, workorder.toDTO(), auth)
		.then((res: AxiosResponse) => {
			return new Workorder(res.data);
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};
export const UpdateWorkorder = async (workorder: Workorder, expands?: string[]): Promise<Workorder | ErrorResponse> => {
	const auth = getAuthHeader();
	const url = `/partner/todo/workorder/${workorder.id}` + stringifyExpands(expands);

	return await axios
		.put(url, workorder.toDTO(), auth)
		.then((res: AxiosResponse) => {
			return new Workorder(res.data);
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};

export const UploadWorkorderFile = async (id: string, type: string, file: File, expands?: string[]): Promise<Workorder | ErrorResponse> => {
	const config = getAuthHeader();
	const url = `/partner/todo/workorder/${id}/file?type=${type}` + stringifyExpands(expands, false);

	const formData = new FormData();
	formData.append('file', file);
	config.headers['Content-Type'] = 'multipart/form-data';

	return await axios
		.post(url, formData, config)
		.then((res: AxiosResponse) => {
			return new Workorder(res.data);
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};

export const startWorkorder = async (id: string): Promise<boolean | ErrorResponse> => {
	const auth = getAuthHeader();
	const url = `/partner/todo/workorder/${id}/start`;

	return await axios
		.patch(url, undefined, auth)
		.then(() => {
			return true;
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};
export const getWorkorderConditions = async (id: string): Promise<TruckTelemetryStatus | ErrorResponse> => {
	const auth = getAuthHeader();
	const url = `/partner/todo/workorder/${id}/conditions`;

	return await axios
		.get(url, auth)
		.then((res: AxiosResponse) => {
			return res.data as TruckTelemetryStatus;
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};

export const finishWorkorder = async (id: string): Promise<boolean | ErrorResponse> => {
	const auth = getAuthHeader();
	const url = `/partner/todo/workorder/${id}/finish`;

	return await axios
		.patch(url, undefined, auth)
		.then(() => {
			return true;
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};

export const MergeWorkorder = async (receiver_id: string, merge_id: string, expands?: string[]): Promise<Workorder | ErrorResponse> => {
	const auth = getAuthHeader();
	const url = `/partner/todo/workorder/${receiver_id}/merge?workorder=${merge_id}` + stringifyExpands(expands, false);

	return await axios
		.put(url, {}, auth)
		.then((res: AxiosResponse) => {
			return new Workorder(res.data);
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};
