<template>
	<v-navigation-drawer
		v-model="drawerMenu"
		clipped
		fixed
		app
		height="100%"
	>
		<h1
			class="text-center heading-bold hidden-sm-and-up primary--text pa-4 pb-0"
			style="font-size: x-large"
			v-html="title"
		/>
		<div class="partner-info pa-4">
			<v-img
				v-if="PARTNER.logo"
				:src="PARTNER.logo"
				:alt="PARTNER.name"
				:title="PARTNER.name"
				contain
				max-height="150"
			>
				<template v-slot:placeholder>
					<v-row
						class="fill-height ma-0"
						align="center"
						justify="center"
					>
						<v-progress-circular
							indeterminate
							color="grey lighten-5"
						/>
					</v-row>
				</template>
			</v-img>
			<h4
				v-else
				class="text-center heading-bold"
				style="font-size: x-large"
			>
				{{ PARTNER.name }}
			</h4>
		</div>

		<v-list>
			<v-list-item
				v-for="(item, i) in itemsAllowed"
				:key="i"
				:to="item.to"
				router
				exact
			>
				<v-list-item-action>
					<v-icon color="primary"> {{ item.icon }} </v-icon>
				</v-list-item-action>
				<v-list-item-content>
					<v-list-item-title class="heading-bold">{{ item.title }}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</v-list>

		<!-- Contact dialog box -->
		<template
			v-if="false"
			v-slot:append
		>
			<v-list>
				<v-list-item
					:to="{ name: RoutesNames.helpCenter }"
					router
					exact
				>
					<v-list-item-action>
						<v-icon color="primary">mdi-help-circle-outline</v-icon>
					</v-list-item-action>
					<v-list-item-content>
						<v-list-item-title class="heading-bold">{{ $t('menu.help_center') }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</template>
	</v-navigation-drawer>
</template>

<script lang="ts">
import { actionAllowed } from '@/utils';

import { mapState } from 'vuex';
import { TranslateResult } from 'vue-i18n';
import { Route } from 'vue-router';
import { RoutesNames } from '@/router';
import Vue from 'vue';

interface IMenuItem {
	icon: string;
	title: string | TranslateResult;
	perm: string;
	to: Route;
}

export default Vue.extend({
	name: 'DrawerMenu',

	data() {
		return {
			title: this.$t('document_title.fss'),
			RoutesNames: RoutesNames,

			//contact dialog
			contact: this.$buinfo.contact()
		};
	},

	computed: {
		itemsAllowed(): IMenuItem[] {
			const items = [
				{
					icon: 'mdi-format-list-bulleted',
					title: this.$t('menu.todo'),
					perm: 'partnertodo:list',
					to: { name: RoutesNames.todo }
				},
				{
					icon: 'mdi-truck-outline',
					title: this.$t('menu.vehicles'),
					perm: 'truck:list',
					to: { name: RoutesNames.truck }
				},
				{
					icon: 'mdi-account-group',
					title: this.$t('menu.users'),
					perm: 'user:list',
					to: { name: RoutesNames.user }
				},
				{
					icon: 'mdi-chart-line',
					title: this.$t('menu.stats.title'),
					perm: 'partnerstats:list',
					to: { name: RoutesNames.stats }
				},
				{
					icon: 'mdi-file-document-multiple-outline',
					title: this.$t('menu.accounting'),
					perm: 'partneraccounting:list',
					to: { name: RoutesNames.accounting }
				}
			] as IMenuItem[];

			return items.filter((item) => actionAllowed(item.perm));
		},

		drawerMenu: {
			// This is spaghetti non sense because vue throws an error if vuex computed prop is used in a v-model without a setter
			get: function (): boolean {
				return this.DRAWER;
			},
			set: function (val: boolean) {
				this.$store.state.drawerMenu = val; // not used
			}
		},

		...mapState({
			PARTNER: 'partner',
			DRAWER: 'drawerMenu'
		})
	}
});
</script>
